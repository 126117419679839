export enum ExotwiinStatus {
  InProgress = 'In Progress',
  Raw3DPreview = '3D Capture',
}

export interface ExotwiinInterface {
  name: string;
  uuid: string;
  date: string;
  scanId: string;
  status: ExotwiinStatus;
  svgUri: string;
  fbxUri: string;
  objUri: string;
  gifUri: string;
  mp4Uri: string;
}
