import { Injectable } from '@angular/core';
import { CompanyName, RoleName, User } from '@prisma/client';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
import { Subscription, tap } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { ExotwiinUIModel } from '../models';
import {
  CoreAuthRepository,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { StatusState } from '@ngneat/elf-requests';

@Injectable({
  providedIn: 'root',
})
export class ExotwiinUIController implements DisposableInterface {
  private readonly _authUserSubscription: Subscription;
  private readonly _signupConfirmedSubscription: Subscription;

  constructor(
    private readonly _model: ExotwiinUIModel,
    private readonly _coreAuthRepository: CoreAuthRepository,
    private readonly _coreFeatureFlag: CoreFeatureFlagService
  ) {
    this.onUserChanged = this.onUserChanged.bind(this);
    this.onConfirmSignup = this.onConfirmSignup.bind(this);

    this._authUserSubscription = this._coreAuthRepository.user$.subscribe(
      this.onUserChanged
    );

    this._signupConfirmedSubscription = this._coreAuthRepository
      .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP_CONFIRM)
      .pipe(tap(this.onConfirmSignup))
      .subscribe();
  }

  public dispose(): void {
    this._authUserSubscription.unsubscribe();
    this._model.dispose();
  }

  private async onUserChanged(
    user:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ): Promise<void> {
    await this._coreFeatureFlag.identify(user?.id);
    this._model.isUserLoggedIn = Object.keys(user ?? {}).length > 0;
  }

  public async onConfirmSignup(status: StatusState): Promise<void> {
    if (status.value === 'pending') {
      this._model.isSignupConfirmed = false;
    } else if (status.value === 'success') {
      this._model.isSignupConfirmed = true;
    } else if (status.value === 'error') {
      this._model.isSignupConfirmed = false;
    }
  }
}
