import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { CompanyName, RoleName, User } from '@prisma/client';
import { Model } from '../model';

export interface HeaderProps {
  user:
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined;
  isConnected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<HeaderProps>({
        user: undefined,
        isConnected: false,
      })
    );
    super(
      new Store({
        name: 'header',
        state: state,
        config: config,
      })
    );
  }

  public get user():
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined {
    return this.store.getValue().user;
  }

  public set user(
    value:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ) {
    this.store.update((state) => ({ ...state, user: value }));
  }

  public get isConnected(): boolean {
    return this.store.getValue().isConnected;
  }

  public set isConnected(value: boolean) {
    this.store.update((state) => ({ ...state, isConnected: value }));
  }
}
