import { OnInit, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { StatusState } from '@ngneat/elf-requests';
import { TranslateService } from '@ngx-translate/core';
import { ICredentials } from '@aws-amplify/core';
import {
  CoreAuthRepository,
  CoreAuthService,
  CoreAuthSettingsRepository,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { Subscription, tap } from 'rxjs';
import { AuthenticationController } from '../../../../../controllers';
import { AuthenticationModel } from '../../../../../models';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'exotwiin-ui-auth-signup',
  templateUrl: './exotwiin-ui-signup.page.html',
  styleUrls: ['./exotwiin-ui-signup.page.scss'],
})
export class SignUpPage implements OnInit, OnDestroy {
  private _routeSubscription: Subscription | undefined;
  codeQuantity = 6;
  form: FormGroup;
  codeForm: FormGroup;
  loadingSubscriptions: Array<Subscription> = [];
  currentLang = this.coreAuthSettingsRepository?.storeValue?.currentLanguage;
  invalidPassword = false;
  isCodeCompleted = false;
  invalidCode = false;
  isSignUpDone = false;
  isCodeDone = false;
  codeError = false;

  constructor(
    private readonly _authController: AuthenticationController,
    private readonly coreAuthService: CoreAuthService,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private readonly loadingController: LoadingController,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private authentificationModel: AuthenticationModel,
    private authService: CoreAuthService
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/\d/),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/\W/),
      ]),
    });
    this.codeForm = new FormGroup({
      code: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.pattern(/^.{6,}$/),
      ]),
    });
  }

  public get model(): AuthenticationModel {
    return this.authentificationModel;
  }

  /**
   * Get email Field
   */
  get getEmail() {
    return this.form.value.email;
  }

  public ngOnInit() {
    this.setLoadingSubscriptions();

    this._routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.resetFormState();
      }
    });
  }

  public ngOnDestroy() {
    this._routeSubscription?.unsubscribe();
    this.loadingSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  public resetFormState() {
    this.form.reset();
    this.codeForm.reset();
    this.isCodeCompleted = false;
    this.isCodeDone = false;
    this.isSignUpDone = false;
  }

  public completeSignUpForm() {
    if (!this.form.valid) return;

    return this._authController.signupAsync(
      this.form.get('email')?.value,
      this.form.get('password')?.value
    );
  }

  public signInWithSSO(
    provider: Parameters<typeof this.authService['signInFederated']>[0]
  ): Promise<ICredentials> {
    return this.authService.signInFederated(provider);
  }

  private async setLoadingSubscriptions() {
    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP)
        .pipe(
          tap(async ({ value: statusState }: StatusState) => {
            if (statusState === 'pending') {
              const signUpLoading = await this.loadingController.create({
                id: 'signup',
                message: this.translateService.instant('signUp.signingUp'),
              });

              return await signUpLoading.present();
            }

            if (['success', 'error'].includes(statusState)) {
              await this.loadingController.dismiss(
                undefined,
                undefined,
                'signup'
              );

              if (statusState === 'success') {
                this.isSignUpDone = true;
              }
            }
          })
        )
        .subscribe()
    );

    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP_CONFIRM)
        .pipe(
          tap(async ({ value: statusState }: StatusState) => {
            if (statusState === 'pending') {
              const signUpConfirmLoading = await this.loadingController.create({
                id: 'verify-code',
                message: this.translateService.instant('signUp.verifyingCode'),
              });

              return await signUpConfirmLoading.present();
            }

            if (['success', 'error'].includes(statusState)) {
              await this.loadingController.dismiss(
                undefined,
                undefined,
                'verify-code'
              );
            }
          })
        )
        .subscribe()
    );
  }

  isMinimumLenghtMet(): boolean {
    const { password } = this.form.value;
    return password && password.length >= 8;
  }

  isThereNumber(): boolean {
    const { password } = this.form.value;
    return password && password.match(/\d/);
  }

  isThereUppercaseLetter(): boolean {
    const { password } = this.form.value;
    return password && password.match(/[A-Z]/);
  }

  isThereLowercaseLetter(): boolean {
    const { password } = this.form.value;
    return password && password.match(/[a-z]/);
  }

  isThereSymbol(): boolean {
    const { password } = this.form.value;
    return password && password.match(/\W/);
  }

  /**
   * Set the Code filled by the User
   * @param event Event emitted on fill input
   */
  async setCode(event: any) {
    this.isCodeCompleted = true;

    const codeStatus = await this.coreAuthService.signUpConfirm({
      username: this.getEmail,
      code: event,
    });

    if (codeStatus.toUpperCase() == 'SUCCESS') {
      this.isCodeDone = true;
      this.codeError = false;
    } else {
      this.isCodeCompleted = false;
      this.codeError = true;
    }
  }
}
