import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { CoreAuthStoreProvider } from '@serious-stack/core/auth/angular';
import { Model } from '../model';

export interface LandingProps {
  user: CoreAuthStoreProvider.AuthProps | undefined;
  isConnected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LandingModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<LandingProps>({
        user: undefined,
        isConnected: false,
      })
    );
    super(
      new Store({
        name: 'landing',
        state: state,
        config: config,
      })
    );
  }

  public get user(): CoreAuthStoreProvider.AuthProps | undefined {
    return this.store.getValue().user;
  }

  public set user(value: CoreAuthStoreProvider.AuthProps | undefined) {
    this.store.update((state) => ({ ...state, user: value }));
  }

  public get isConnected(): boolean {
    return this.store.getValue().isConnected;
  }

  public set isConnected(value: boolean) {
    this.store.update((state) => ({ ...state, isConnected: value }));
  }
}
