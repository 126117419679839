import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { CompanyName, RoleName, User } from '@prisma/client';
import { ExotwiinJson } from '../json/exotwiin.json';
import { Model } from '../model';

export interface CodeProps {
  user:
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined;
  exotwiins: ExotwiinJson[];
  exotwiin: ExotwiinJson | undefined;
  scanId: string;
  exotwiinUuid: string;
}

@Injectable({
  providedIn: 'root',
})
export class CodeModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<CodeProps>({
        user: undefined,
        exotwiins: [],
        exotwiin: undefined,
        scanId: '',
        exotwiinUuid: '',
      })
    );
    super(
      new Store({
        name: 'code',
        state: state,
        config: config,
      })
    );
  }

  public get user():
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined {
    return this.store.getValue().user;
  }

  public set user(
    value:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ) {
    this.store.update((state) => ({ ...state, user: value }));
  }

  public get exotwiins(): ExotwiinJson[] {
    return this.store.getValue().exotwiins;
  }

  public set exotwiins(value: ExotwiinJson[]) {
    this.store.update((state) => ({ ...state, exotwiins: value }));
  }

  public get exotwiin(): ExotwiinJson | undefined {
    return this.store.getValue().exotwiin;
  }

  public set exotwiin(value: ExotwiinJson | undefined) {
    this.store.update((state) => ({ ...state, exotwiin: value }));
  }

  public get scanId(): string {
    return this.store.getValue().scanId;
  }

  public set scanId(value: string) {
    this.store.update((state) => ({ ...state, scanId: value }));
  }

  public set exotwiinUuid(value: string) {
    this.store.update((state) => ({ ...state, exotwiinUuid: value }));
  }

  public get exotwiinUuid(): string {
    return this.store.getValue().exotwiinUuid;
  }
}
