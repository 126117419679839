<div class="flex flex-col">
  <div class="flex flex-row justify-center" [class]="codeNumberClass">
    <ion-item *ngFor="let count of counter(codeNumberQuantity); let i = index" #codeItems class="text-center input-code-item"
      lines="none">
      <ion-input class="input-code-field p-0 flex justify-center align-center opacity-100 text-center bg-neutral-700 rounded-md" data-testId="confirmEmail-input-code"
        #singleCodeInput (paste)="onPaste($event)" [class.invalid]="codeArray[i] === ''
              && (codeArray.length === codeNumberQuantity
              || codeNotCompleted)
              || invalidCode" type="search" maxlength="1" (keypress)="decimalOnlyValidation($event)"
        (ionChange)="fillCodeValue(i, $event)" (ionFocus)="changePosition(i, $event)" [value]="codeArray[i]"
        inputmode="search" required="true" autofocus="{{i === 0}}">
      </ion-input>
    </ion-item>
    <ion-input class="hidden" [value]="codeInput" #codeInput required="true">
    </ion-input>
  </div>
  <!-- Invalid code -->
  <div class="input-error mt-4 text-red-500 mx-auto" data-testId="signup-lbl-invalidConfirmationCode" *ngIf="invalidCode">
    {{ 'resetPassword.invalidCode' | translate }}
  </div>
</div>
