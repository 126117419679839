import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { finalize } from 'rxjs';

@Injectable()
export class CoreErrorTrackingInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const transaction = Sentry.startTransaction({
      op: 'http.client',
      name: `${request.method} ${request.url}`,
      tags: {
        method: request.method,
        url: request.url,
        query: request.params.toString(),
        body: JSON.stringify(request.body),
      },
    });
    Sentry.configureScope((scope) => {
      scope.setSpan(transaction);
    });

    return next.handle(request).pipe(
      finalize(() => {
        transaction.finish();
      })
    );
  }
}
