import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { CompanyName, RoleName, User } from '@prisma/client';
import { ExotwiinInterface } from '../interfaces/exotwiin.interface';
import { ExotwiinUserJson } from '../json/exotwiin-user.json';
import { ExotwiinJson } from '../json/exotwiin.json';
import { Model } from '../model';

export interface HomeProps {
  user:
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined;
  exotwiinUser: ExotwiinUserJson | undefined;
  isDrawerOpen: boolean;
  isExotwiinLoading: boolean;
  exotwiins: ExotwiinInterface[];
  selectedExotwiin?: ExotwiinJson;
  isExotwiinInitialLoad: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HomeModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<HomeProps>({
        user: undefined,
        exotwiinUser: undefined,
        isDrawerOpen: false,
        isExotwiinLoading: false,
        exotwiins: [],
        isExotwiinInitialLoad: true,
      })
    );
    super(
      new Store({
        name: 'home',
        state: state,
        config: config,
      })
    );
  }

  public get user():
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined {
    return this.store.getValue().user;
  }

  public set user(
    value:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ) {
    this.store.update((state) => ({ ...state, user: value }));
  }

  public get exotwiinUser(): ExotwiinUserJson | undefined {
    return this.store.getValue().exotwiinUser;
  }

  public set exotwiinUser(value: ExotwiinUserJson | undefined) {
    this.store.update((state) => ({ ...state, exotwiinUser: value }));
  }

  public get isDrawerOpen(): boolean {
    return this.store.getValue().isDrawerOpen;
  }

  public set isDrawerOpen(value: boolean) {
    this.store.update((state) => ({ ...state, isDrawerOpen: value }));
  }

  public get isExotwiinLoading(): boolean {
    return this.store.getValue().isExotwiinLoading;
  }

  public set isExotwiinLoading(value: boolean) {
    this.store.update((state) => ({ ...state, isExotwiinLoading: value }));
  }

  public get isExotwiinInitialLoad(): boolean {
    return this.store.getValue().isExotwiinInitialLoad;
  }

  public set isExotwiinInitialLoad(value: boolean) {
    this.store.update((state) => ({ ...state, isExotwiinInitialLoad: value }));
  }

  public get exotwiins(): ExotwiinInterface[] {
    return this.store.getValue().exotwiins;
  }

  public set exotwiins(value: ExotwiinInterface[]) {
    this.store.update((state) => ({ ...state, exotwiins: value }));
  }

  public get selectedExotwiin(): ExotwiinJson {
    return this.store.getValue().selectedExotwiin;
  }

  public set selectedExotwiin(value: ExotwiinJson) {
    this.store.update((state) => ({ ...state, selectedExotwiin: value }));
  }
}
