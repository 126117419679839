import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreFeatureFlagModule } from '@serious-stack/core/feature-flag/angular';
import { HeaderController } from '../../controllers/header.controller';
import { HeaderModel } from '../../models';
import { HeaderComponent } from './header/exotwiin-ui-header.page';
import { MenuComponent } from './menu/exotwiin-ui-menu.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { ValidationCodeComponent } from './validation-code/validation-code.component';

@NgModule({
    providers: [HeaderController, HeaderModel],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        CoreFeatureFlagModule
    ],
    exports: [HeaderComponent, ValidationCodeComponent, ShowHidePasswordComponent],
    declarations: [HeaderComponent, MenuComponent, ValidationCodeComponent, ShowHidePasswordComponent],
})
export class ExotwiinUIComponentModule {}