import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { from, mergeMap } from 'rxjs';
import { CoreAuthAmplifyProvider } from '../providers';

@Injectable()
export class CoreAuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.url.includes('i18n')) {
      return next.handle(request);
    }

    return from(this.coreAuthAmplify.currentSession()).pipe(
      mergeMap((session: any) => {
        const jwtToken = session.idToken.jwtToken;

        request = request.clone({
          withCredentials: false,
          setHeaders: {
            Authorization: jwtToken,
          },
        });

        return next.handle(request);
      })
    );
  }
}
