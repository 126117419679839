import { Injectable } from '@angular/core';
import { CompanyName, RoleName, User } from '@prisma/client';
import { CoreAuthRepository } from '@serious-stack/core/auth/angular';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
import { Subscription } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { HeaderModel } from '../models/header.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderController implements DisposableInterface {
  private readonly _userSubscription: Subscription;

  constructor(
    private readonly _model: HeaderModel,
    private readonly _coreAuthRepository: CoreAuthRepository,
    private readonly _coreFeatureFlag: CoreFeatureFlagService
  ) {
    this.onUserChanged = this.onUserChanged.bind(this);

    this._userSubscription = this._coreAuthRepository.user$.subscribe(
      this.onUserChanged
    );
  }

  public dispose(): void {
    this._userSubscription.unsubscribe();
    this._model.dispose();
  }

  private onUserChanged(
    user:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ): void {
    this._model.user = user;
    this._model.isConnected = user !== undefined;
    this._coreFeatureFlag.identify(user?.id);
  }
}
