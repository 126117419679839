import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CoreAuthRepository } from '@serious-stack/core/auth/angular';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';

@Component({
  selector: 'exotwiin-ui-header',
  templateUrl: 'exotwiin-ui-header.page.html',
})
export class HeaderComponent {
  user$ = this.coreAuthRepository.user$;
  isConnected = false;
  @Input() mode = 'default';

  _isMenuOpened = false;

  constructor(
    private coreAuthRepository: CoreAuthRepository,
    private coreFeatureFlag: CoreFeatureFlagService,
    private _router: Router
  ) {
    this.user$?.subscribe((user) => {
      this.coreFeatureFlag.identify(user?.id);
      if (user) {
        this.isConnected = true;
      } else {
        this.isConnected = false;
      }
    });
  }

  public get router(): Router {
    return this._router;
  }

  public get isOnLoginPage(): boolean {
    return this.router.url === '/auth/signin';
  }

  signInRedirect() {
    if(this.isConnected){
      this.router.navigate(['/home']);
    }else{
      this.router.navigate(['/auth/signin']);
    }
  }

  signUpRedirect() {
    this.router.navigate(['/auth/signup']);
  }

  portalRedirect() {
    this.router.navigate(['/home']);
  }

  showMenu(): void {
    this._isMenuOpened = true;
  }

  @HostListener('document:click', ['$event'])
  hideMenu(): void {
    this._isMenuOpened = false;
  }

  toggleMenu(event: MouseEvent): void {
    event.stopPropagation();
    this._isMenuOpened = !this._isMenuOpened;
  }
}
