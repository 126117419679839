import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import {
  CoreAuthModule,
  CoreAuthRepository,
} from '@serious-stack/core/auth/angular';
import * as Sentry from '@sentry/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreErrorTrackingInterceptor } from './core-error-tracking.interceptor';

@NgModule({
  imports: [CommonModule, CoreAuthModule],
  providers: [
    {
      provide: ErrorHandler,
      deps: [CoreAuthRepository],
      useValue: Sentry.createErrorHandler({
        logErrors: (process.env as any).NG_APP_PRODUCTION === 'false',
        extractor: (error: any, defaultExtractor) => {
          if (error?.rejection?.name) {
            error.name = error?.rejection?.name;
          }
          if (
            error?.rejection?.error?.name &&
            error?.rejection?.error?.message
          ) {
            error.message = ''.concat(
              error.rejection.error.name,
              ': ',
              error.rejection.error.message,
              ' ',
              error?.message
            );
          }
          return defaultExtractor(error);
        },
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (coreAuthRepository: CoreAuthRepository) => () => {
        Sentry.addGlobalEventProcessor((event: Sentry.Event) => {
          if (coreAuthRepository.storeValue.user) {
            event.user = {
              ...event.user,
              ...coreAuthRepository.storeValue.user,
            };
          }

          return event;
        });
      },
      deps: [CoreAuthRepository],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreErrorTrackingInterceptor,
      multi: true,
    },
  ],
})
export class CoreErrorTrackingModule {}
