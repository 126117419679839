import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { ExotwiinUserJson } from '../json/exotwiin-user.json';
import { ExotwiinJson } from '../json/exotwiin.json';

@Injectable({
  providedIn: 'root',
})
export class ExotwiinUserService implements DisposableInterface {
  private readonly _exotwiinUserBehaviorSubject: BehaviorSubject<
    ExotwiinUserJson | undefined
  >;
  private readonly _exotwiinsBehaviourSubject: BehaviorSubject<ExotwiinJson[]>;
  private _getSubscription: Subscription | undefined;
  private _getExotwiinsSubscription: Subscription | undefined;
  private _createSubscription: Subscription | undefined;

  constructor(private readonly _http: HttpClient) {
    this._exotwiinUserBehaviorSubject = new BehaviorSubject<
      ExotwiinUserJson | undefined
    >(undefined);
    this._exotwiinsBehaviourSubject = new BehaviorSubject<ExotwiinJson[]>([]);
  }

  public get exotwiinUserObservable(): Observable<
    ExotwiinUserJson | undefined
  > {
    return this._exotwiinUserBehaviorSubject.asObservable();
  }

  public get exotwiinsObservable(): Observable<ExotwiinJson[]> {
    return this._exotwiinsBehaviourSubject.asObservable();
  }

  public dispose(): void {
    this.unsubscribeGetSubscription();
    this.unsubscribeGetExotwiinsSubscription();
    this.unsubscribeCreateSubscription();
  }
  
  public clearExotwiins(){
    this._exotwiinsBehaviourSubject.next([]);
  }

  public async requestGetAsync(cognitoId: string): Promise<ExotwiinUserJson> {
    this.unsubscribeGetSubscription();

    return new Promise<ExotwiinUserJson>((resolve, reject) => {
      const json = new ExotwiinUserJson();
      this._getSubscription = this._http
        .get(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/exotwiin-user-handler/v1/exotwiin-user/${cognitoId}`
        )
        .subscribe({
          next: (response: object) => {
            const deserialized = json.deserialize(JSON.stringify(response));
            this._exotwiinUserBehaviorSubject.next(deserialized);
            resolve(deserialized);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    });
  }

  public async requestGetExotwiinsAsync(
    exotwiinUserId: string
  ): Promise<ExotwiinJson[]> {
    this.unsubscribeGetExotwiinsSubscription();

    return new Promise<ExotwiinJson[]>((resolve, reject) => {
      this._getExotwiinsSubscription = this._http
        .get(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/exotwiin-user-handler/v1/exotwiin-user/exotwiins/${exotwiinUserId}`
        )
        .subscribe({
          next: (response: object) => {
            const exotwiinJsons: ExotwiinJson[] = [];
            const exotwiins: object[] = response as object[];
            for (const exotwiin of exotwiins) {
              const json = new ExotwiinJson();
              const exotwiinJson = json.deserialize(JSON.stringify(exotwiin));
              exotwiinJsons.push(exotwiinJson);
            }

            exotwiinJsons.sort((twiin1, twiin2) => {
              if (twiin1.date > twiin2.date) return -1;
              if (twiin1.date < twiin2.date) return 1;
              return 0;
            })
            this._exotwiinsBehaviourSubject.next(exotwiinJsons);
            resolve(exotwiinJsons);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    });
  }

  public async requestCreateAsync(
    cognitoId: string
  ): Promise<ExotwiinUserJson> {
    this.unsubscribeCreateSubscription();

    return new Promise<ExotwiinUserJson>((resolve, reject) => {
      const json = new ExotwiinUserJson();
      const body = {
        cognitoId: cognitoId,
      };
      this._createSubscription = this._http
        .post(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/exotwiin-user-handler/v1/exotwiin-user/create`,
          body
        )
        .subscribe({
          next: (response: object) => {
            const deserialized = json.deserialize(JSON.stringify(response));
            this._exotwiinUserBehaviorSubject.next(deserialized);
            resolve(deserialized);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    });
  }

  private unsubscribeGetSubscription(): void {
    if (!this._getSubscription?.closed) {
      this._getSubscription?.unsubscribe();
    }
  }

  private unsubscribeGetExotwiinsSubscription(): void {
    if (!this._getExotwiinsSubscription?.closed) {
      this._getExotwiinsSubscription?.unsubscribe();
    }
  }

  private unsubscribeCreateSubscription(): void {
    if (!this._createSubscription?.closed) {
      this._createSubscription?.unsubscribe();
    }
  }
}
