import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CoreAuthAmplifyProvider } from '../providers';
import { AuthRequest } from '../providers/core-auth-store.provider';
import { CoreAuthRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthGuard implements CanActivate {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const redirectError: string = route?.data?.redirectError;

    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.IS_LOGGED_IN,
        'pending'
      );

      await this.coreAuthAmplify.currentSession();

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.IS_LOGGED_IN,
        'success'
      );

      return true;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.IS_LOGGED_IN,
        'error',
        error
      );

      if (redirectError) {
        return this.router.parseUrl(redirectError);
      }

      throw error;
    }
  }
}
