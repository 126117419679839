<ion-header class="ion-no-border w-[100%]">
    <div class="pl-8 pr-8 h-[10vh] w-full bg-neutral-900 flex justify-between items-center flex-row">
        <img (click)="signInRedirect()" src="../../../assets/images/exotwiin_headerIcon.svg" alt="">
        <ng-container *ngIf="!isConnected; else connected">
            <ng-container *ngIf="isOnLoginPage; else logIn">
                <button class="text-white" (click)="signUpRedirect()">
                    {{ 'signUp.signUp' | translate }}
                </button>
            </ng-container>
            <ng-template #logIn>
                <button class="text-white" (click)="signInRedirect()">
                    {{ 'signIn.login' | translate }}
                </button>
            </ng-template>
        </ng-container>
        <ng-template #connected>
            <exotwiin-ui-menu [class.hidden]="!this._isMenuOpened" [isVisible]="this._isMenuOpened"></exotwiin-ui-menu>
            <img (click)="toggleMenu($event)" src="../../../assets/icon/user_icon.svg" alt="">
        </ng-template>
    </div>
</ion-header>