import { createState, Store, withProps } from '@ngneat/elf';
import { withRequestsStatus } from '@ngneat/elf-requests';
import { InjectionToken } from '@angular/core';
import { CompanyName, RoleName, User } from '@prisma/client';

export enum AuthRequest {
  SIGNIN = 'signin',
  SIGNIN_FEDERATED = 'signin-federated',
  SIGNOUT = 'signout',
  SIGNUP = 'signup',
  SIGNUP_CONFIRM = 'signup-confirm',
  SIGNUP_RESEND_VERIFICATION_CODE = 'signup-resend-verification-code',
  RESET_PASSWORD = 'reset-password',
  SUBMIT_RESET_PASSWORD = 'submit-reset-password',
  INVITATION = 'invitation',
  IS_LOGGED_IN = 'is-logged-in',
}

export interface AuthProps {
  user?: User & {
    userRoleCompanies: Array<{
      companyName: CompanyName;
      roleName: RoleName;
    }>;
  };
}

const name = 'auth';
const { state, config } = createState(
  withProps<AuthProps>({ user: undefined }),
  withRequestsStatus<AuthRequest.SIGNIN>(),
  withRequestsStatus<AuthRequest.SIGNIN_FEDERATED>(),
  withRequestsStatus<AuthRequest.SIGNOUT>(),
  withRequestsStatus<AuthRequest.SIGNUP>(),
  withRequestsStatus<AuthRequest.SIGNUP_CONFIRM>(),
  withRequestsStatus<AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE>(),
  withRequestsStatus<AuthRequest.RESET_PASSWORD>(),
  withRequestsStatus<AuthRequest.SUBMIT_RESET_PASSWORD>(),
  withRequestsStatus<AuthRequest.INVITATION>(),
  withRequestsStatus<AuthRequest.IS_LOGGED_IN>()
);
const authStore = new Store({ name, state, config });

export type providerType = typeof authStore;
export const providerToken = new InjectionToken<providerType>(
  'CORE_AUTH_STORE',
  {
    providedIn: 'root',
    factory: () => authStore,
  }
);

export default {
  provide: providerToken,
  useValue: authStore,
};
