import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouteReuseStrategy } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ResetPasswordPage, SignInPage, SignUpPage } from './pages';
import { CoreAuthModule } from '@serious-stack/core/auth/angular';
import { environment } from '../../../environments/environment';
import { ExampleUIAuthRoutingModule } from './exotwiin-ui-auth-routing.module';
import { ExotwiinUIComponentModule } from '../../components/exotwiin-ui-component.module';
import { ExotwiinUIAuthService } from './exotwiin-ui-auth.service';
import { AuthenticationController } from '../../../controllers';
import { AuthenticationModel } from '../../../models';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    ExotwiinUIComponentModule,
    CoreAuthModule.withConfig({
      features: {
        signUp: environment.features.signUp,
        sso: environment.features.sso,
        invitation: environment.features.invitation,
      },
    }),
    ExampleUIAuthRoutingModule,
  ],
  declarations: [SignInPage, SignUpPage, ResetPasswordPage],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    ExotwiinUIAuthService,
    AuthenticationController,
    AuthenticationModel,
  ],
})
export class ExotwiinAuthModule {}
