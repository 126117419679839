<exotwiin-ui-header></exotwiin-ui-header>

<ion-content>
  <section class="flex flex-col items-center justify-between">
    <section class="flex flex-col justify-center text-center">
      <div class="font-poppins text-h4 font-bold text-white mt-3">Log In</div>
      <div class="font-poppins text-p3 font-regular text-neutral-100 max-w-xs mt-1 mb-7">
        {{ 'signIn.welcomeBack' | translate }}! {{ 'signIn.welcomeDesc' | translate }}!
      </div>
      <div class="border rounded-lg border-neutral-600 flex py-2">
        <div class="rounded-lg bg-neutral-700 w-7 h-7 mx-3">
          <div class="ml-[0.6rem] mt-[0.3rem]">
            <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.23333 18.0001V9.554H0V6.51299H2.23333V3.91557C2.23333 1.8745 3.55257 0.00012207 6.59237 0.00012207C7.82314 0.00012207 8.73324 0.118112 8.73324 0.118112L8.66153 2.9579C8.66153 2.9579 7.73338 2.94886 6.72053 2.94886C5.62433 2.94886 5.44871 3.45403 5.44871 4.29249V6.51299H8.74867L8.60508 9.554H5.44871V18.0001H2.23333Z"
                fill="#E7E5EB" />
            </svg>
          </div>
        </div>
        <button class="opacity-100 font-poppins text-p2 text-white font-medium mt-1"
          (click)="signInWithSSO('facebook')">
          {{ 'signIn.signInWithFacebook' | translate }}
        </button>
      </div>
    </section>

    <section class="flex flex-row items-center my-5">
      <div class="border-b border-neutral-800 w-24 flex-1"></div>
      <div class="font-poppins text-neutral-600 font-regular text-p3 mx-3">{{ 'signIn.orWithEmail' | translate }}</div>
      <div class="border-b border-neutral-800 w-24 flex-1"></div>
    </section>

    <form class="flex flex-col w-min" [formGroup]="form" (ngSubmit)="signIn()">
      <!-- Email -->
      <div class="flex flex-col">
        <label class="font-poppins text-p3 font-medium text-neutral-400 mb-1">
          {{ 'forms.email' | translate | titlecase }}
        </label>
        <input
          class="w-80 border-2 border-transparent py-3 px-3 bg-white rounded-md text-p1 font-poppins font-regular text-neutral-900 hover:border-2 hover:border-primary-200"
          [class.invalid]="!form.controls.email.valid
                    && (form.controls.email.dirty
                    || form.controls.email.touched)
                    || invalidPassword" type="email" (onChange)="invalidPassword = false" formControlName="email"
          [placeholder]="'forms.enterEmail' | translate" inputmode="email" autocomplete="email" required="true" />
      </div>

      <!-- Errors -->
      <div *ngIf="form.controls.email.invalid
          && (form.controls.email.dirty || form.controls.email.touched)">
        <!-- Required -->
        <div class="font-poppins font-regular text-caption text-neutral-400 pt-1"
          *ngIf="form.controls.email.errors?.['required']">
          {{ 'forms.requiredField' | translate }}
        </div>
        <!-- Invalid format -->
        <div class="font-poppins font-regular text-caption text-neutral-400 pt-1"
          *ngIf="form.controls.email.errors?.['email']">
          {{ 'forms.invalidEmail' | translate }}
        </div>
      </div>

      <!-- Password -->
      <div class="flex flex-col mt-4">
        <label class="font-poppins text-p3 font-medium text-neutral-400 mb-1">
          {{ 'forms.password' | translate | titlecase }}
        </label>
        <input
          class="w-80 border-2 border-transparent py-3 px-3 bg-white rounded-md text-p1 font-poppins font-regular text-neutral-900 hover:border-2 hover:border-primary-200"
          [class.invalid]="!form.controls.password.valid
                    && (form.controls.password.dirty
                    || form.controls.password.touched)
                    || invalidPassword" type="password" (ionChange)="invalidPassword = false"
          formControlName="password" [placeholder]="'forms.enterPassword' | translate" inputmode="password"
          autocomplete="password" required="true" />
      </div>


      <!-- Errors -->
      <p *ngIf="errorMessage" class="text-red-500 text-center m-auto pt-4">
        {{errorMessage}}
      </p>

      <div class="flex flex-row justify-end mt-4 mb-7">
        <div class="font-poppins font-regular text-p3 text-neutral-400 mr-3 align-text-bottom self-center">{{
          'signIn.rememberMe' | translate }}</div>
        <ion-toggle class="scale-75" [value]="isRemembered" (ionChange)="onRememberMeChanged($event)" checked>
        </ion-toggle>
      </div>

      <button
        class="{{this.form.valid ? 'opacity-100' : 'opacity-50' }} bg-primary-500 rounded-lg text-white py-3 text-h6 font-medium">
        {{ 'signIn.login' | translate | titlecase }}
      </button>

      <a class="font-poppins text-p3 font-semibold text-primary-500 self-center mt-6"
        [routerLink]="['/auth/reset-password']">
        {{ 'signIn.resetPassword' | translate }}
      </a>
    </form>

    <!-- Footer -->
    <section class="px-6 mt-16 mb-5 font-poppins text-caption text-neutral-500 text-center">
      {{ 'signIn.byClickingSignUp' | translate }} <span (click)="openTermsAndConditionPage()"
        class="font-semibold text-primary-500">{{ 'signIn.termsCondition' | translate }}</span> and
      <span (click)="openPrivacyPolicyPage()" class="font-semibold text-primary-500">{{ 'signIn.privacyPolicy' |
        translate }}</span>.
    </section>
  </section>
</ion-content>