import { JsonInterface } from '../interfaces/json.interface';

export class ExotwiinUserJson implements JsonInterface<ExotwiinUserJson> {
  private _uuid: string;
  private _cognitoId: string;

  constructor() {
    this._uuid = '';
    this._cognitoId = '';
  }

  public get uuid(): string {
    return this._uuid;
  }

  public get cognitoId(): string {
    return this._cognitoId;
  }

  public deserialize(json: string): ExotwiinUserJson {
    const object = JSON.parse(json);
    this._uuid = object['uuid'];
    this._cognitoId = object['cognitoId'];

    return this;
  }
}
