import { Component } from '@angular/core';
import {
  CoreAuthRepository,
  CoreAuthService,
} from '@serious-stack/core/auth/angular';
import { Router } from '@angular/router';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
@Component({
  selector: 'exotwiin-ui-landing',
  styleUrls: ['exotwiin-ui-landing.page.scss'],
  templateUrl: 'exotwiin-ui-landing.page.html',
})
export class LandingPage {
  user$ = this.coreAuthRepository.user$;
  isConnected = false;

  constructor(private router: Router, 
    private coreAuthRepository: CoreAuthRepository,
    private coreFeatureFlag: CoreFeatureFlagService,
) {
    this.user$?.subscribe((user) => {
      this.coreFeatureFlag.identify(user?.id);
      if (user) {
        this.isConnected = true;
      } else {
        this.isConnected = false;
      }
    });
}

  public redirect() {
    if(!this.isConnected){
      this.router.navigate(['/auth/signup']);
    }else{
      this.router.navigate(['/home']);
    }
  }

  redirectToTermsAndConditions(): void {
    this.router.navigate(['landing/terms-and-conditions']);
  }

  redirectToPrivacyPolicy(): void {
    this.router.navigate(['landing/privacy-policy']);
  }
}
