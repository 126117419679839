import { Inject, Injectable, OnDestroy } from '@angular/core';
import { select } from '@ngneat/elf';
import {
  createRequestsStatusOperator,
  updateRequestStatus,
  selectRequestStatus,
} from '@ngneat/elf-requests';
import { CompanyName, RoleName } from '@prisma/client';
import {
  CoreAuthSettingsStoreProvider,
  CoreAuthStoreProvider,
} from '../providers';
import {
  persistState,
  localStorageStrategy,
  excludeKeys,
} from '@ngneat/elf-persist-state';
import { Subscription } from 'rxjs';
import { AuthRequest } from '../providers/core-auth-store.provider';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthRepository implements OnDestroy {
  private readonly _isRememberedSubscription: Subscription;

  constructor(
    @Inject(CoreAuthStoreProvider.providerToken)
    public authStore: CoreAuthStoreProvider.providerType,
    @Inject(CoreAuthSettingsStoreProvider.providerToken)
    public authSettingsStore: CoreAuthSettingsStoreProvider.providerType
  ) {
    this.persistStateAsync();

    this._isRememberedSubscription = authSettingsStore
      .pipe(select(({ isRemembered }) => isRemembered))
      .subscribe(() => this.persistStateAsync());
  }

  public get store$() {
    return this.authStore;
  }

  public get storeValue() {
    return this.authStore.getValue();
  }

  public get user$() {
    return this.authStore.pipe(select(({ user }) => user));
  }

  public get trackRequestsStatus() {
    return createRequestsStatusOperator(this.authStore);
  }

  public selectRequestStatus(request: CoreAuthStoreProvider.AuthRequest) {
    return this.authStore.pipe(selectRequestStatus(request));
  }

  updateRequestStatus(
    key: AuthRequest,
    value: Parameters<typeof updateRequestStatus>[1] | 'error',
    error?: Parameters<typeof updateRequestStatus>[2]
  ) {
    return this.authStore.update(
      updateRequestStatus(key as AuthRequest, value as any, error)
    );
  }

  public getUserRoleByCompany(
    companyName: CompanyName = CompanyName.exotwiin
  ) {
    const user = this.storeValue.user;
    if (!user) {
      throw new Error('User undefined');
    }

    return user.userRoleCompanies.find(
      (userRoleCompany) => userRoleCompany.companyName === companyName
    )?.roleName as RoleName;
  }

  public updateUser(user: Partial<CoreAuthStoreProvider.AuthProps['user']>) {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        ...user,
      } as CoreAuthStoreProvider.AuthProps['user'],
    }));
  }

  public reset() {
    this.authStore.reset();
  }

  private async persistStateAsync(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      persistState(this.authStore, {
        key: this.authStore.name,
        storage: localStorageStrategy,
        source: () => this.authStore.pipe(excludeKeys(['requestsStatus'])),
        runGuard: () => {
          const isRemembered = this.authSettingsStore.getValue().isRemembered;
          const storageValue = localStorageStrategy.getItem(
            this.authStore.name
          );
          if (storageValue && isRemembered === false) {
            localStorageStrategy.removeItem(this.authStore.name);
          }
          return isRemembered ?? false;
        },
      }).initialized$.subscribe({
        error: (error: Error) => {
          reject(error);
        },
        complete: () => {
          resolve();
        },
      });
    });
  }

  ngOnDestroy(): void {
    this._isRememberedSubscription?.unsubscribe();
  }
}
