import { Injectable } from '@angular/core';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { LandingModel } from '../models/landing.model';

@Injectable({
  providedIn: 'root',
})
export class LandingController implements DisposableInterface {
  constructor(private readonly _model: LandingModel) {}

  public dispose(): void {
    this._model.dispose();
  }
}
