import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  CoreAuthStoreProvider,
  CoreAuthConfigProvider,
  CoreAuthService,
  CoreAuthRepository,
  CoreAuthSettingsRepository,
  CoreAuthSettingsStoreProvider,
} from '@serious-stack/core/auth/angular';
import { NavigationStart, Router } from '@angular/router';
import { ICredentials } from '@aws-amplify/core';
import { ExotwiinUIAuthService } from '../../exotwiin-ui-auth.service';
import { AuthenticationModel } from '../../../../../models/authentication.model';
import { AuthenticationController } from '../../../../../controllers/authentication.controller';
import { select } from '@ngneat/elf';

@Component({
  selector: 'exotwiin-ui-auth-signin',
  templateUrl: './exotwiin-ui-signin.page.html',
  styleUrls: ['./exotwiin-ui-signin.page.scss'],
})
export class SignInPage implements AfterViewInit, OnDestroy, OnInit {
  private _form: FormGroup;
  private _loadingSubscriptions: Array<Subscription> = [];
  private _userSubscription: Subscription | undefined;
  private _accountErrorSubscription: Subscription | undefined;
  private _isLoginLoadingSubscription: Subscription | undefined;
  private _isSignupUnconfirmedSubscription: Subscription | undefined;

  errorMessage: string | undefined;
  invalidPassword = false;
  routeSub: any;

  constructor(
    @Inject(CoreAuthConfigProvider.providerToken)
    private readonly config: CoreAuthConfigProvider.providerType,
    private readonly authService: CoreAuthService,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private readonly loadingController: LoadingController,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly exotwiinUIAuthService: ExotwiinUIAuthService,
    private readonly _authModel: AuthenticationModel,
    private readonly authController: AuthenticationController
  ) {
    this.onIsSignupUnconfirmed = this.onIsSignupUnconfirmed.bind(this);

    this._form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
    });
  }

  public ngOnInit() {
    this._isLoginLoadingSubscription = this.authModel.store
      .pipe(select((model) => model.isLoginLoading))
      .subscribe(async (value: boolean) => {
        if (value) {
          const signInLoading = await this.loadingController.create({
            id: 'signin',
            message: `${this.translateService.instant('signIn.signingIn')} ...`,
          });

          return await signInLoading?.present();
        } else {
          await this.loadingController.dismiss(undefined, undefined, 'signin');
        }
      });

    this._userSubscription = this.authModel.store
      .pipe(select((model: AuthenticationModel) => model.user))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['home']);
        }
      });

    this._accountErrorSubscription = this.authModel.store
      .pipe(select((model: AuthenticationModel) => model.accountError))
      .subscribe((value) => {
        if (value !== "") {
          this.errorMessage = value;
        }
      });

    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.errorMessage = undefined;
      }
    });

    this._isSignupUnconfirmedSubscription = this.authModel.store
      .pipe(select((model: AuthenticationModel) => model.isSignupUnconfirmed))
      .subscribe(this.onIsSignupUnconfirmed);
  }

  public ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this._isSignupUnconfirmedSubscription?.unsubscribe();
    this._loadingSubscriptions.forEach((sub) => sub?.unsubscribe());
    this._isLoginLoadingSubscription?.unsubscribe();
    this._userSubscription?.unsubscribe();
  }

  public get form(): FormGroup {
    return this._form;
  }

  public get isSSOActivated(): boolean {
    return this.config.features.sso;
  }

  public get isSignUpActivated(): boolean {
    return this.config.features.signUp;
  }

  public get loadingSubscriptions(): Array<Subscription> {
    return this._loadingSubscriptions;
  }

  public get model(): CoreAuthSettingsStoreProvider.AuthSettingsProps {
    return this.coreAuthSettingsRepository?.storeValue;
  }

  public get authModel(): AuthenticationModel {
    return this._authModel;
  }
  

  public get isRemembered(): boolean | undefined {
    return this.model.isRemembered;
  }

  public get authenticatedUser(): CoreAuthStoreProvider.AuthProps['user'] {
    return this.coreAuthRepository?.storeValue.user;
  }

  public ngAfterViewInit(): void {
    if (Object.keys(this.authenticatedUser ?? {}).length > 0) {
      this.router.navigate(['home']);
    }
  }

  public async signIn(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    this.authController.signinAsync(
      this.form.get('email')?.value,
      this.form.get('password')?.value
    );
  }

  public signInWithSSO(
    provider: Parameters<typeof this.authService['signInFederated']>[0]
  ): Promise<ICredentials> {
    return this.authService.signInFederated(provider);
  }

  public onRememberMeChanged(event: any): void {
    this.coreAuthSettingsRepository.setIsRemembered(event.target.checked);
  }

  public openTermsAndConditionPage(): void {
    this.router.navigate(['landing/terms-and-conditions']);
  }

  public openPrivacyPolicyPage(): void {
    this.router.navigate(['landing/privacy-policy']);
  }

  private async onIsSignupUnconfirmed(
    isSignupUnconfirmed: boolean
  ): Promise<void> {
    if (isSignupUnconfirmed) {
      console.log(this.router.url);
      if (this.router.url === '/auth/signin' && this._form.valid) {
        this.exotwiinUIAuthService.openConfirmSignUp(
          this.form.get('email')?.value
        );
      }
    }
  }
}
