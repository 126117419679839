import { ExtraErrorData } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import * as Tracing from '@sentry/tracing';

Tracing && true;

export const coreErrorTrackingInit = () => {
  const environmentVariables = Object.entries(process.env)
    .filter(([key]) => key.includes('NG_APP'))
    .reduce(
      (previousValue, [key, value]) => ({
        ...previousValue,
        [key]: value,
      }),
      {}
    );
  const endpoints = Object.entries(environmentVariables)
    .filter(([key]) => key.includes('ENDPOINT'))
    .map(([_, value]) => value) as Array<string>;

  Sentry.init({
    dsn: process.env.NG_APP_SENTRY_DSN,
    environment: process.env.NG_APP_ENVIRONMENT,
    release: process.env.NG_APP_GIT_COMMIT_LONG,
    tracesSampleRate: 1,
    enabled: !process.env.NG_APP_IS_OFFLINE as any as boolean,
    normalizeDepth: 11,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', ...endpoints, /^\//],
        routingInstrumentation: Sentry.instrumentAngularRouting,
      }),
      new ExtraErrorData({ depth: 10 }),
    ],
    initialScope: {
      tags: {
        app: process.env.NG_APP_NAME,
      },
      extra: {
        environment: environmentVariables,
      },
    },
  });
};
