import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { Model } from '../model';

export interface ExotwiinUIProps {
  isUserLoggedIn: boolean | undefined;
  isSignupConfirmed: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ExotwiinUIModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<ExotwiinUIProps>({
        isUserLoggedIn: undefined,
        isSignupConfirmed: false,
      })
    );
    super(
      new Store({
        name: 'exotwiin-ui',
        state: state,
        config: config,
      })
    );
  }

  public get isUserLoggedIn(): boolean {
    return this.store.getValue().isUserLoggedIn;
  }

  public set isUserLoggedIn(value: boolean) {
    this.store.update((state) => ({ ...state, isUserLoggedIn: value }));
  }

  public get isSignupConfirmed(): boolean {
    return this.store.getValue().isSignupConfirmed;
  }

  public set isSignupConfirmed(value: boolean) {
    this.store.update((state) => ({ ...state, isSignupConfirmed: value }));
  }
}
