<!-- Go Back Header -->
<exotwiin-ui-header></exotwiin-ui-header>

<ion-content class="flex reset-password-content">
  <ion-grid class="flex items-center justify-center h-[75vh] p-0">
    <ion-row class="flex m-0 justify-center">

      <!-- Send Email Container -->
      <ion-col [class.hidden]="isPasswordResettled || resetPasswordSubmitted"
        class=" flex-col items-center  ion-justify-content-center p-0 h-full" size="12" size-lg="8">
        <ion-col class="flex text-center w-full
             flex-col mt-4 p-0" size-lg="7" size="12">
          <h1 class="text-h4 font-bold text-white mt-0 mb-1">
            {{ "resetPassword.resetPassword" | translate | titlecase }}
          </h1>
          <p class="text-p3 ion-text-center text-neutral-400 mb-5">
            {{ "resetPassword.resetPasswordSubtitle" | translate }}
          </p>
        </ion-col>

        <ion-col class="flex flex-col mt-4 p-0" size="12" size-lg="7">
          <!-- Send Email to Reset -->
          <form class=" flex-col" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()"
            *ngIf="!isPasswordResettled">

            <div class="text-neutral-400 text-p3 mb-1">{{ 'forms.email' | translate }}</div>
            <input
              class="h-14 w-full border-solid border-2 border-neutral-700 bg-white rounded-lg text-neutral-900 px-4 py-3"
              [class.invalid]="!resetPasswordForm.controls.email.valid
                        && (resetPasswordForm.controls.email.dirty
                        || resetPasswordForm.controls.email.touched)" type="email" formControlName="email"
              [placeholder]="'forms.email' | translate | titlecase" inputmode="email"
              data-testId="resetPassword-input-email" autocomplete="email" required="true" />
            <!-- To here -->

            <!-- Errors From -->
            <div *ngIf="resetPasswordForm.controls.email.invalid
                && (resetPasswordForm.controls.email.dirty
                || resetPasswordForm.controls.email.touched)">
              <!-- Required -->
              <div class="input-error text-red-500 mt-2 mx-auto" *ngIf="resetPasswordForm.controls.email.errors?.['required']">
                {{ 'forms.requiredField' | translate }}
              </div>
              <!-- Invalid format -->
              <div class="input-error text-red-500 mt-2 mx-auto" *ngIf="resetPasswordForm.controls.email.errors?.['email']">
                {{ 'forms.invalidEmail' | translate }}
              </div>
            </div>
            <!-- To here -->

            <!-- Send Email Button -->
            <button
              class="{{!resetPasswordForm.valid || showSpinner ? 'opacity-50' : ''}} w-full bg-primary-500 text-white mt-8 p-4 rounded-md"
              type="submit" data-testId="reset-password-button-signIn"
              [disabled]="!resetPasswordForm.valid || showSpinner">
              {{'resetPassword.sendEmail' | translate | titlecase}}
            </button>
            <!-- To here -->
            <a [routerLink]="['..']" class="cancel-button ion-text-center mt-3 d-lg-block hidden">
              {{ "general.cancel" | translate | titlecase }}
            </a>
          </form>
        </ion-col>
      </ion-col>

      <!-- Complete code/password for Reset -->
      <ion-col *ngIf="isPasswordResettled" class=" flex-col ion-align-items-center
              ion-justify-content-center p-0" size="12" size-lg="7">

        <!-- Set Code -->
        <ion-col *ngIf="!isCodeCompleted" class=" flex flex-col text-center px-3 py-0" size="12" size-lg="8">
          <p class="text-h4 font-bold d-lg-none d-block">
            {{'resetPassword.confirmEmail' | translate | titlecase}}
          </p>
          <p class="text-center text-p3 text-neutral-400 mb-6 ">
            {{'resetPassword.resetPasswordCodeSubtitle1' | translate}} {{getEmail}}
          </p>

        </ion-col>

        <!-- Set Password -->
        <ion-col *ngIf="isCodeCompleted" class="ion-align-items-center flex flex-col px-3 p-0 text-h4 font-bold"
          size="12" size-lg="8">
          <h1 class="reset-password-header-title ion-text-center mb-1 mt-0 w-full text-center">
            {{ "resetPassword.newPassword" | translate | titlecase }}
          </h1>
          <span class="text-p3 text-neutral-400 w-full text-center font-regular mb-10">
            {{ "resetPassword.enterNewPassword" | translate }}
          </span>
        </ion-col>

        <div class="flex flex-col p-0 mt-5" size="12" size-lg="8">
          <form class="ion-align-items-center flex flex-col h-full ion-justify-content-center w-full"
            [formGroup]="submitPasswordForm" (ngSubmit)="submitResetPassword()">

            <!-- Set Code -->
            <exotwiin-ui-validation-code *ngIf="!isCodeCompleted" [invalidCode]="invalidCode"
              (codeCompleted)="setCode($event)" [codeNumberQuantity]="codeQuantity" [codeNumberClass]="'code-class'">
            </exotwiin-ui-validation-code>

            <p *ngIf="!isCodeCompleted" class="text-center text-p3 text-neutral-400 mt-6">
              {{'resetPassword.didntReceive' | translate}}<span class="text-primary-500"> {{'resetPassword.resendCode' |
                translate}}.</span>
            </p>

            <!-- Set Passwords -->
            <div *ngIf="isCodeCompleted" class="mt-4 w-full flex flex-col">
              <div class="text-neutral-400 text-p3 mb-1">{{'resetPassword.newPassword' | translate | titlecase}}</div>
              <exotwiin-ui-show-hide-password>
                <input
                  class="h-14 w-full border-solid border-2 border-neutral-700 bg-white rounded-lg text-neutral-900 px-4 py-3"
                  [class.invalid]="!submitPasswordForm.controls.password.valid
                      && (submitPasswordForm.controls.password.dirty
                      || submitPasswordForm.controls.password.touched)" type="password" formControlName="password"
                  [placeholder]="'signIn.password' | translate | titlecase" inputmode="password"
                  data-testId="resetPassword-input-password" autocomplete="password" required="true" />
              </exotwiin-ui-show-hide-password>
              <!-- To here -->

              <!-- Reusable Input item -- Confirm Password - From-->
              <div class="input-item mt-6" lines="none">
                <div class="text-neutral-400 text-p3 mb-1">{{'resetPassword.confirmPassword' | translate | titlecase}}
                </div>
                <exotwiin-ui-show-hide-password>
                  <input
                    class="h-14 w-full border-solid border-2 border-neutral-700 bg-white rounded-lg text-neutral-900 px-4 py-3"
                    [class.invalid]="!submitPasswordForm.controls.confirmPassword.valid
                      && (submitPasswordForm.controls.confirmPassword.dirty
                      || submitPasswordForm.controls.confirmPassword.touched)" type="password"
                    formControlName="confirmPassword" [placeholder]="'resetPassword.confirmPassword' | translate"
                    inputmode="confirmPassword" data-testId="reset-password-input-password-confirm"
                    autocomplete="password" (click)="$event.stopPropagation()" required="true" />
                </exotwiin-ui-show-hide-password>
                <div class="mt-4 text-p3 {{this.doesPasswordMatch() ? 'text-green-500' : 'text-neutral-400'}}">
                  {{ 'resetPassword.mustMatch' | translate }}
                </div>
                <div class="flex flex-col justify-between mt-4 text-p3 text-neutral-400">
                  <span class="{{this.isMinimumLenghtMet() ? 'text-green-500' : 'text-red-500'}}">*
                    {{'resetPassword.characterCountCriteria' | translate}}</span>
                  <span class="{{this.isThereNumber() ? 'text-green-500' : 'text-red-500'}}">*
                    {{'resetPassword.numberCriteria' | translate}}</span>
                  <span class="{{this.isThereUppercaseLetter() ? 'text-green-500' : 'text-red-500'}}">*
                    {{'resetPassword.uppercaseCriteria' | translate}}</span>
                  <span class="{{this.isThereLowercaseLetter() ? 'text-green-500' : 'text-red-500'}}">*
                    {{'resetPassword.lowercaseCriteria' | translate}}</span>
                  <span class="{{this.isThereSymbol() ? 'text-green-500' : 'text-red-500'}}">*
                    {{'resetPassword.symbolCriteria' | translate}}</span>
                </div>
              </div>

              <!-- Reset Password Button -->
              <button
                class="{{!submitPasswordForm.valid ? 'opacity-50' : '' }} w-full bg-primary-500 mt-8 p-4 rounded-md"
                type="submit" [disabled]="!submitPasswordForm.valid">
                {{ 'resetPassword.resetPassword' | translate | titlecase }}
              </button>
              <!-- To here -->
              <a (click)="handleRedirection()" class="cancel-button ion-text-center mt-3 d-lg-block hidden">
                {{ "general.cancel" | translate | titlecase }}
              </a>
            </div>
          </form>
        </div>
      </ion-col>

      <!-- Reset Password Done -->
      <div *ngIf="resetPasswordSubmitted" class="ion-align-items-center flex flex-col mt-4 w-full px-1">
        <h1 class="mt-4 mb-1 text-center w-full text-h4 font-bold">
          {{ "resetPassword.passwordConfirmed" | translate | titlecase }}
        </h1>
        <h3 class="text-p3 text-neutral-100 weight-400 text-center lg:mx-5 my-0">
          {{ "resetPassword.passwordSuccessfullyChanged" | translate }}
        </h3>

        <img class="w-32 my-10 self-center" src="../../../../../assets/icon/circle-check.svg">
        <h3 class="text-p3 text-neutral-100 weight-400 text-center lg:mx-5 my-0">
          {{ "resetPassword.pleaseLogin" | translate }}
        </h3>

        <!-- Log In Button -->
        <button class="w-full bg-primary-500 mt-4 p-2 rounded-md" [routerLink]="['/auth']" type="submit">
          {{ 'signIn.login' | translate }}
        </button>
        <!-- To here -->
      </div>
    </ion-row>
  </ion-grid>
</ion-content>

<!-- <custom-spinner *ngIf="showSpinner"></custom-spinner> -->
