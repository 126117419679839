import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { select } from '@ngneat/elf';
import { StatusState } from '@ngneat/elf-requests';
import { TranslateService } from '@ngx-translate/core';
import {
  CoreAuthRepository,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { filter, Subscription, tap } from 'rxjs';
import {
  AuthenticationController,
  CodeController,
  DetailsController,
  HomeController,
  LandingController,
  ExotwiinUIController,
} from '../controllers';
import { HeaderController } from '../controllers/header.controller';
import { ExotwiinUIModel } from '../models';
import { ExotwiinService, ExotwiinUserService } from '../services';

@Component({
  selector: 'exotwiin-ui-root',
  templateUrl: 'exotwiin-ui.component.html',
  styleUrls: ['exotwiin-ui.component.scss'],
})
export class ExotwiinUIComponent implements OnInit, OnDestroy {
  private _isUserLoggedInSubscription: Subscription | undefined;
  private _routerEventSubscription: Subscription | undefined;
  private _loadingSubscriptions: Array<Subscription> = [];

  constructor(
    private readonly _exotwiinUIModel: ExotwiinUIModel,
    private readonly _exotwiinUIController: ExotwiinUIController,
    private readonly _authenticationController: AuthenticationController,
    private readonly _codeController: CodeController,
    private readonly _detailsController: DetailsController,
    private readonly _headerController: HeaderController,
    private readonly _homeController: HomeController,
    private readonly _landingController: LandingController,
    private readonly _exotwiinUserService: ExotwiinUserService,
    private readonly _exotwiinService: ExotwiinService,
    private readonly loadingController: LoadingController,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly translateService: TranslateService,
    private readonly _router: Router
  ) {
    this.onIsUserLoggedIn = this.onIsUserLoggedIn.bind(this);
  }

  public ngOnInit(): void {
    this._isUserLoggedInSubscription = this._exotwiinUIModel.store
      .pipe(select((model: ExotwiinUIModel) => model.isUserLoggedIn))
      .subscribe(this.onIsUserLoggedIn);

    this.setLoadingSubscriptions();
  }

  public ngOnDestroy() {
    if (!this._routerEventSubscription?.closed) {
      this._routerEventSubscription?.unsubscribe();
    }

    this._isUserLoggedInSubscription?.unsubscribe();
    this._loadingSubscriptions.forEach((sub) => sub?.unsubscribe());
    this._exotwiinUIController.dispose();
    this._authenticationController.dispose();
    this._codeController.dispose();
    this._detailsController.dispose();
    this._headerController.dispose();
    this._homeController.dispose();
    this._landingController.dispose();
    this._exotwiinUserService.dispose();
    this._exotwiinService.dispose();
  }

  public async setLoadingSubscriptions(): Promise<void> {
    const signInFederatedLoading = await this.loadingController.create({
      message: `${this.translateService.instant('signIn.signingIn')} ...`,
    });

    this._loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNIN_FEDERATED)
        .pipe(
          tap(async ({ value: statusState }: StatusState) => {
            switch (statusState) {
              case 'pending':
                return await signInFederatedLoading?.present();

              case 'error':
              case 'success':
                this._router.navigate(['home']);
                return await signInFederatedLoading.dismiss();
            }
          })
        )
        .subscribe()
    );

    const signOutLoading = await this.loadingController.create({
      message: `${this.translateService.instant('signIn.signingOut')} ...`,
    });

    this._loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNOUT)
        .pipe(
          tap(async ({ value: statusState }: StatusState) => {
            switch (statusState) {
              case 'pending':
                return await signOutLoading?.present();

              case 'error':
              case 'success':
                this._router.navigate(['auth']);
                return await signOutLoading.dismiss();
            }
          })
        )
        .subscribe()
    );
  }

  private onIsUserLoggedIn(isLoggedIn: boolean): void {
    if (!this._routerEventSubscription?.closed) {
      this._routerEventSubscription?.unsubscribe();
    }

    this._routerEventSubscription = this._router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!isLoggedIn) {
          if (event.url === '/') {
            this._router.navigate(['landing']);
          }
        } else {
          if (event.url === '/') {
            this._router.navigate(['home']);
          }
        }

        this._routerEventSubscription?.unsubscribe();
      });
  }
}
