import { createState, Store, withProps } from '@ngneat/elf';
import { InjectionToken } from '@angular/core';

export enum AvailableLanguages {
  EN = 'en',
  FR = 'fr',
}

export interface AuthSettingsProps {
  isRemembered: boolean | undefined;
  currentLanguage: AvailableLanguages;
}

const name = 'auth-settings';
const { state, config } = createState(
  withProps<AuthSettingsProps>({
    isRemembered: true,
    currentLanguage: AvailableLanguages.EN,
  })
);

const authSettingsStore = new Store({ name, state, config });

export type providerType = typeof authSettingsStore;
export const providerToken = new InjectionToken<providerType>(
  'CORE_AUTH_SETTINGS_STORE',
  {
    providedIn: 'root',
    factory: () => authSettingsStore,
  }
);

export default {
  provide: providerToken,
  useValue: authSettingsStore,
};
