import { Injectable } from '@angular/core';
import { CompanyName, RoleName, User } from '@prisma/client';
import { CoreAuthRepository } from '@serious-stack/core/auth/angular';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
import { Subscription } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { ExotwiinJson } from '../json/exotwiin.json';
import { CodeModel } from '../models/code.model';
import { ExotwiinUserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class CodeController implements DisposableInterface {
  private readonly _userSubscription: Subscription;
  private readonly _exotwiinsSubscription: Subscription;

  constructor(
    private readonly _model: CodeModel,
    private readonly _exotwiinUserService: ExotwiinUserService,
    private readonly _coreAuthRepository: CoreAuthRepository,
    private readonly _coreFeatureFlag: CoreFeatureFlagService
  ) {
    this.onUserChanged = this.onUserChanged.bind(this);
    this.onExotwiinsChanged = this.onExotwiinsChanged.bind(this);

    this._userSubscription = this._coreAuthRepository.user$.subscribe(
      this.onUserChanged
    );
    this._exotwiinsSubscription =
      this._exotwiinUserService.exotwiinsObservable.subscribe(
        this.onExotwiinsChanged
      );
  }

  public dispose(): void {
    this._exotwiinsSubscription.unsubscribe();
    this._userSubscription.unsubscribe();
    this._model.dispose();
  }

  public setSelectedExotwiin(uuid: string): void {
    const exotwiin = this._model.exotwiins.find(
      (exotwiin: ExotwiinJson) => exotwiin.uuid === uuid
    );
    this._model.exotwiin = exotwiin;
    this._model.scanId = exotwiin?.scanId ?? '';
  }

  private onUserChanged(
    user:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ): void {
    this._model.user = user;
    this._coreFeatureFlag.identify(user?.id);
  }

  private onExotwiinsChanged(exotwiins: ExotwiinJson[]): void {
    this._model.exotwiins = exotwiins;
  }
}
