import { Inject, Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { CoreAuthSettingsStoreProvider } from '../providers';
import {
  AuthSettingsProps,
  AvailableLanguages,
  providerType,
} from '../providers/core-auth-settings-store.provider';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthSettingsRepository {
  constructor(
    @Inject(CoreAuthSettingsStoreProvider.providerToken)
    private readonly authSettingsStore: CoreAuthSettingsStoreProvider.providerType
  ) {
    this.persistStateAsync();
  }

  public get store$(): providerType {
    return this.authSettingsStore;
  }

  public get storeValue(): AuthSettingsProps {
    return this.authSettingsStore.getValue();
  }

  public get isRemembered(): Observable<boolean | undefined> {
    return this.authSettingsStore.pipe(
      select(({ isRemembered }) => isRemembered)
    );
  }

  public get currentLang$(): Observable<AvailableLanguages> {
    return this.authSettingsStore.pipe(
      select(({ currentLanguage }) => currentLanguage)
    );
  }

  public setIsRemembered(isRemembered: boolean): void {
    this.authSettingsStore.update((state) => ({ ...state, isRemembered }));
  }

  public setCurrentLanguage(currentLanguage: AvailableLanguages): void {
    this.authSettingsStore.update((state) => ({ ...state, currentLanguage }));
  }

  private async persistStateAsync(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      persistState(this.authSettingsStore, {
        key: this.authSettingsStore.name,
        storage: localStorageStrategy,
      }).initialized$.subscribe({
        error: (error: Error) => {
          reject(error);
        },
        complete: () => {
          resolve();
        },
      });
    });
  }
}
