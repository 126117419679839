import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'exotwiin-ui-back-button-header',
  templateUrl: './exotwiin-ui-back-button-header.page.html',
  styleUrls: []
})
export class BackButtonHeaderPage implements OnInit {
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }


  redirectHome(): void {
    this.router.navigate(['home']);
  }
}
