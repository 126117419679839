import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreAuthService } from '@serious-stack/core/auth/angular';
import { ExotwiinUserService } from '../../../services/exotwiin-user.service';

@Component({
  selector: 'exotwiin-ui-menu',
  templateUrl: './exotwiin-ui-menu.component.html',
  styleUrls: []
})
export class MenuComponent implements OnInit {
  @Input() isVisible = false;

  constructor(
    private authService: CoreAuthService,
    private exotwiinUserService: ExotwiinUserService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  signOut(): Promise<void> {
    this.exotwiinUserService.clearExotwiins();
    return this.authService.signOut();
  }

  openProfileSettingsPage(): void {
    this.router.navigate(['home/profile']);
  }

  openTermsAndConditionPage(): void {
    this.router.navigate(['landing/terms-and-conditions']);
  }

  openPrivacyPolicyPage(): void {
    // todo: Once the page is created, navigate to it from here
    this.router.navigate(['landing/privacy-policy']);
  }
}
