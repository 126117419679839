import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { CompanyName, RoleName, User } from '@prisma/client';
import { ExotwiinUserJson } from '../json/exotwiin-user.json';
import { Model } from '../model';

export interface AuthenticationProps {
  user:
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined;
  isLoginLoading: boolean;
  isSignupLoading: boolean;
  isResetPasswordLoading: boolean;
  isSignupUnconfirmed: boolean;
  exotwiinUser: ExotwiinUserJson | undefined;
  isExotwiinUserLoading: boolean;
  isPasswordResetRequested: boolean;
  userExistsError: boolean;
  accountError: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<AuthenticationProps>({
        user: undefined,
        isLoginLoading: false,
        isSignupLoading: false,
        isResetPasswordLoading: false,
        isSignupUnconfirmed: false,
        exotwiinUser: undefined,
        isExotwiinUserLoading: false,
        isPasswordResetRequested: false,
        userExistsError: false,
        accountError: "",
      })
    );
    super(
      new Store({
        name: 'authentication',
        state: state,
        config: config,
      })
    );
  }

  public get user():
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined {
    return this.store.getValue().user;
  }

  public set user(
    value:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ) {
    this.store.update((state) => ({ ...state, user: value }));
  }

  public get isLoginLoading(): boolean {
    return this.store.getValue().isLoginLoading;
  }

  public set isLoginLoading(value: boolean) {
    this.store.update((state) => ({ ...state, isLoginLoading: value }));
  }

  public get isSignupLoading(): boolean {
    return this.store.getValue().isSignupLoading;
  }

  public set isSignupLoading(value: boolean) {
    this.store.update((state) => ({ ...state, isSignupLoading: value }));
  }

  public get isResetPasswordLoading(): boolean {
    return this.store.getValue().isResetPasswordLoading;
  }

  public set isResetPasswordLoading(value: boolean) {
    this.store.update((state) => ({ ...state, isResetPasswordLoading: value }));
  }

  public get isSignupUnconfirmed(): boolean {
    return this.store.getValue().isSignupUnconfirmed;
  }

  public set isSignupUnconfirmed(value: boolean) {
    this.store.update((state) => ({ ...state, isSignupUnconfirmed: value }));
  }

  public get exotwiinUser(): ExotwiinUserJson | undefined {
    return this.store.getValue().exotwiinUser;
  }

  public set exotwiinUser(value: ExotwiinUserJson | undefined) {
    this.store.update((state) => ({ ...state, exotwiinUser: value }));
  }

  public get isExotwiinUserLoading(): boolean {
    return this.store.getValue().isExotwiinLoading;
  }

  public set isExotwiinUserLoading(value: boolean) {
    this.store.update((state) => ({ ...state, isExotwiinLoading: value }));
  }

  public get isPasswordResetRequested(): boolean {
    return this.store.getValue().isPasswordResetRequested;
  }

  public set isPasswordResetRequested(value: boolean) {
    this.store.update((state) => ({
      ...state,
      isPasswordResetRequested: value,
    }));
  }

  public get userExistsError(): boolean {
    return this.store.getValue().userExistsError;
  }

  public set userExistsError(value: boolean) {
    this.store.update((state) => ({
      ...state,
      userExistsError: value,
    }));
  }

  public get accountError(): string {
    return this.store.getValue().accountError;
  }

  public set accountError(value: string) {
    this.store.update((state) => ({
      ...state,
      accountError: value,
    }));
  }
}
