import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { ExotwiinJson } from '../json/exotwiin.json';

@Injectable({
  providedIn: 'root',
})
export class ExotwiinService implements DisposableInterface {
  private _getSubscription: Subscription | undefined;
  private _createSubscription: Subscription | undefined;

  constructor(private readonly _http: HttpClient) {}

  public dispose(): void {
    this.unsubscribeGetSubscription();
    this.unsubscribeCreateSubscription();
  }

  public async requestGetAsync(uuid: string): Promise<ExotwiinJson> {
    this.unsubscribeGetSubscription();

    return new Promise<ExotwiinJson>((resolve, reject) => {
      const json = new ExotwiinJson();
      this._getSubscription = this._http
        .get(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/exotwiin-handler/v1/exotwiin/${uuid}`
        )
        .subscribe({
          next: (response: object) => {
            resolve(json.deserialize(JSON.stringify(response)));
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    });
  }

  public async requestCreateAsync(
    cognitoId: string,
    name: string
  ): Promise<ExotwiinJson> {
    this.unsubscribeCreateSubscription();

    return new Promise<ExotwiinJson>((resolve, reject) => {
      const json = new ExotwiinJson();
      const body = {
        name: name,
      };
      this._createSubscription = this._http
        .post(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/exotwiin-handler/v1/exotwiin/create/${cognitoId}`,
          body
        )
        .subscribe({
          next: (response: object) => {
            const exotwiinJson = json.deserialize(JSON.stringify(response));
            resolve(exotwiinJson);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    });
  }

  private unsubscribeGetSubscription(): void {
    if (!this._getSubscription?.closed) {
      this._getSubscription?.unsubscribe();
    }
  }

  private unsubscribeCreateSubscription(): void {
    if (!this._createSubscription?.closed) {
      this._createSubscription?.unsubscribe();
    }
  }
}
