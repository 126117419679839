import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CoreAuthConfig } from './interfaces';
import { CoreAuthConfigProvider } from './providers';
import { CoreAuthInterceptor } from './interceptors';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
  ],
})
export class CoreAuthModule {
  static withConfig(
    config: CoreAuthConfig
  ): ModuleWithProviders<CoreAuthModule> {
    CoreAuthModule;
    return {
      ngModule: CoreAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CoreAuthInterceptor,
          multi: true,
        },
        {
          provide: CoreAuthConfigProvider.providerToken,
          useValue: config,
        },
        TitleCasePipe,
      ],
    };
  }
}
