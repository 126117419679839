import { Injectable } from '@angular/core';
import { CompanyName, RoleName, User } from '@prisma/client';
import {
  CoreAuthRepository,
  CoreAuthService,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
import { Subscription, tap } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { AuthenticationModel } from '../models/authentication.model';
import { ICredentials } from '@aws-amplify/core';
import { StatusState } from '@ngneat/elf-requests';
import { ExotwiinUserService } from '../services/exotwiin-user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ExotwiinUserJson } from '../json/exotwiin-user.json';
import { UsernameExistsException } from '@aws-sdk/client-cognito-identity-provider';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationController implements DisposableInterface {
  private readonly _authUserSubscription: Subscription;
  private readonly _signinSubscription: Subscription;
  private readonly _signupSubscription: Subscription;
  private readonly _signupConfirmedSubscription: Subscription;
  private readonly _resetPasswordSubscription: Subscription;
  private readonly _exotwiinUserSubscription: Subscription;

  constructor(
    private readonly _model: AuthenticationModel,
    private readonly _exotwiinUserService: ExotwiinUserService,
    private readonly _authService: CoreAuthService,
    private readonly _coreAuthRepository: CoreAuthRepository,
    private readonly _coreFeatureFlag: CoreFeatureFlagService
  ) {
    this.onUserChanged = this.onUserChanged.bind(this);
    this.onSignin = this.onSignin.bind(this);
    this.onSignup = this.onSignup.bind(this);
    this.onConfirmSignup = this.onConfirmSignup.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.onExotwiinUser = this.onExotwiinUser.bind(this);

    this._authUserSubscription = this._coreAuthRepository.user$.subscribe(
      this.onUserChanged
    );
    this._signinSubscription = this._coreAuthRepository
      .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNIN)
      .pipe(tap(this.onSignin))
      .subscribe();
    this._signupSubscription = this._coreAuthRepository
      .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP)
      .pipe(tap(this.onSignup))
      .subscribe();
    this._resetPasswordSubscription = this._coreAuthRepository
      .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.RESET_PASSWORD)
      .pipe(tap(this.onResetPassword))
      .subscribe();
    this._signupConfirmedSubscription = this._coreAuthRepository
      .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP_CONFIRM)
      .pipe(tap(this.onConfirmSignup))
      .subscribe();
    this._exotwiinUserSubscription =
      this._exotwiinUserService.exotwiinUserObservable.subscribe(
        this.onExotwiinUser
      );
  }

  public dispose(): void {
    this._exotwiinUserSubscription.unsubscribe();
    this._resetPasswordSubscription.unsubscribe();
    this._signupConfirmedSubscription.unsubscribe();
    this._signupSubscription.unsubscribe();
    this._signinSubscription.unsubscribe();
    this._authUserSubscription.unsubscribe();
    this._model.dispose();
  }

  public async signinAsync(email: string, password: string): Promise<void> {
    await this._authService.signIn({
      username: email,
      password: password,
    });
  }

  public async signinWithSSOAsync(
    provider: Parameters<typeof this._authService['signInFederated']>[0]
  ): Promise<ICredentials> {
    return await this._authService.signInFederated(provider);
  }

  public async signupAsync(email: string, password: string): Promise<void> {
    try {
      await this._authService.signUp({
        username: email,
        password: password,
      });

      this._model.userExistsError = false;
    } catch (error: any) {
      if (error.name === 'UsernameExistsException') {
        this._model.userExistsError = true;
      }
    }
  }

  public async requestPasswordResetAsync(email: string): Promise<void> {
    await this._authService.resetPassword({ username: email });
    this._model.isPasswordResetRequested = true;
  }

  public async submitPasswordResetAsync(
    email: string,
    code: string,
    password: string
  ): Promise<void> {
    await this._authService.submitResetPassword({
      username: email,
      code: code,
      password: password,
    });
    this._model.isPasswordResetRequested = false;
  }

  private async onUserChanged(
    user:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ): Promise<void> {
    this._model.user = user;
    await this._coreFeatureFlag.identify(user?.id);

    if (user !== undefined && Object.keys(user ?? {}).length > 0) {
      this._model.isExotwiinUserLoading = true;

      try {
        this._model.exotwiinUser =
          await this._exotwiinUserService.requestGetAsync(user.id);
      } catch (error: any) {
        if (error instanceof HttpErrorResponse) {
          if (error.status !== 500) {
            this._model.isExotwiinUserLoading = false;
            return;
          }

          try {
            this._model.exotwiinUser =
              await this._exotwiinUserService.requestCreateAsync(user.id);
          } catch (error: any) {
            this._model.isExotwiinUserLoading = false;
          }
        }
      }
    }
  }

  private async onSignin(status: StatusState): Promise<void> {
    if (status.value === 'pending') {
      this._model.isLoginLoading = true;
      this._model.isSignupUnconfirmed = false;
    } else if (status.value === 'success') {
      this._model.isLoginLoading = false;
    } else if (status.value === 'error') {
      this._model.isLoginLoading = false;

      const error = status.error as Error;
      if (error.name === 'UserNotConfirmedException') {
        this._model.isSignupUnconfirmed = true;
        this._model.isLoginLoading = false;
      }else {
        this._model.accountError = error.message;
      }
    }
  }

  private async onSignup(status: StatusState): Promise<void> {
    if (status.value === 'pending') {
      this._model.isSignupLoading = true;
      this._model.isSignupUnconfirmed = false;
    } else if (status.value === 'success') {
      this._model.isSignupLoading = false;
      this._model.isSignupUnconfirmed = true;
    } else if (status.value === 'error') {
      this._model.isSignupLoading = false;
    }
  }

  public async onConfirmSignup(status: StatusState): Promise<void> {
    if (status.value === 'pending') {
      this._model.isSignupUnconfirmed = true;
    } else if (status.value === 'success') {
      this._model.isSignupUnconfirmed = false;
    } else if (status.value === 'error') {
      this._model.isSignupUnconfirmed = true;
    }
  }

  private async onResetPassword(status: StatusState): Promise<void> {
    if (status.value === 'pending') {
      this._model.isResetPasswordLoading = true;
    } else if (status.value === 'success') {
      this._model.isResetPasswordLoading = false;
    } else if (status.value === 'error') {
      this._model.isResetPasswordLoading = false;
    }
  }

  private onExotwiinUser(_user: ExotwiinUserJson | undefined): void {
    this._model.isExotwiinUserLoading = false;
  }
}
