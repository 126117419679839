<div class="
  absolute
  top-0
  left-0
  right-0
  flex
  flex-col
  child:flex
  child:flex-start
  child:items-center
  child:w-full
  child:h-12
  mt-[10vh]
 ">
  <div (click)="openProfileSettingsPage()" class="bg-neutral-700 active:bg-neutral-800">
    <img src="../../../assets/icon/user_wireframe.svg" alt="user icon" class="pl-8 pr-4">
    <span class="text-white">
      {{ 'menu.profileSettings' | translate}}
    </span>
  </div>
  <div (click)="openTermsAndConditionPage()" class="bg-neutral-700 active:bg-neutral-800">
    <img src="../../../assets/icon/file.svg" alt="terms and conditions icon" class="pl-8 pr-4">
    <span class="text-white">
      {{ 'menu.termsAndConditions' | translate}}
    </span>
  </div>
  <div (click)="openPrivacyPolicyPage()" class="bg-neutral-700 active:bg-neutral-800">
    <img src="../../../assets/icon/lock.svg" alt="lock icon" class="pl-8 pr-4">
    <span class="text-white">
      {{ 'menu.privacyPolicy' | translate}}
    </span>
  </div>
  <div (click)="signOut()" class="bg-neutral-700 active:bg-neutral-800">
    <img src="../../../assets/icon/log_out.svg" alt="log out icon" class="pl-8 pr-4">
    <span class="text-white">
      {{ 'menu.logOut' | translate}}
    </span>
  </div>
</div>
