import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';
import { coreErrorTrackingInit } from '@serious-stack/core/error-tracking/angular';
import { ExotwiinUIModule } from './app/exotwiin-ui.module';

if (process.env.NG_APP_PRODUCTION === 'true') {
  enableProdMode();
}

coreErrorTrackingInit();

platformBrowserDynamic()
  .bootstrapModule(ExotwiinUIModule)
  .then((moduleRef) => {
    devTools({
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  })
  .catch((err) => console.error(err));
