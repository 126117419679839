<ion-header class="ion-no-border">
  <div class="h-[10vh] bg-neutral-900 flex items-center">
    <div class="pl-8 pr-8 lg:pr-0 lg:pl-0 flex items-center flex-row" (click)="redirectHome()">
      <img src="../../../../../assets/icon/back_arrow.svg" class="mr-5" alt="">
      <div  class="text-p3 text-white">
        {{ 'header.backToHome' | translate }}
      </div>
    </div>
  </div>
</ion-header>
