import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CoreAuthFeatureGuard,
  CoreAuthConfig,
  CoreAuthFacebookGuard,
} from '@serious-stack/core/auth/angular';
import { ResetPasswordPage, SignInPage, SignUpPage } from './pages';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'signin',
        component: SignInPage,
      },
      {
        path: 'signup',
        component: SignUpPage,
        canActivate: [CoreAuthFeatureGuard],
        data: { feature: 'signUp' } as {
          feature: keyof CoreAuthConfig['features'];
        },
      },
      {
        path: 'reset-password',
        component: ResetPasswordPage,
      },
      {
        path: 'facebook',
        component: {} as any,
        canActivate: [CoreAuthFacebookGuard],
        data: {
          redirectSuccess: '/home',
          redirectError: '/auth',
        },
      },
      {
        path: '**',
        redirectTo: 'signin',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExampleUIAuthRoutingModule {}
