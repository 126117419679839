import { Injectable } from '@angular/core';
import { CompanyName, RoleName, User } from '@prisma/client';
import {
  CoreAuthRepository,
  CoreAuthService,
} from '@serious-stack/core/auth/angular';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
import { Subscription } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import {
  ExotwiinInterface,
  ExotwiinStatus,
} from '../interfaces/exotwiin.interface';
import { ExotwiinUserJson } from '../json/exotwiin-user.json';
import { ExotwiinJson } from '../json/exotwiin.json';
import { HomeModel } from '../models/home.model';
import { ExotwiinService } from '../services';
import { ExotwiinUserService } from '../services/exotwiin-user.service';

@Injectable({
  providedIn: 'root',
})
export class HomeController implements DisposableInterface {
  private readonly _userSubscription: Subscription;
  private readonly _exotwiinUserSubscription: Subscription;
  private readonly _exotwiinsSubscription: Subscription;

  constructor(
    private readonly _model: HomeModel,
    private readonly _exotwiinUserService: ExotwiinUserService,
    private readonly _exotwiinService: ExotwiinService,
    private readonly _authService: CoreAuthService,
    private readonly _coreAuthRepository: CoreAuthRepository,
    private readonly _coreFeatureFlag: CoreFeatureFlagService
  ) {
    this.onUserChanged = this.onUserChanged.bind(this);
    this.onExotwiinUserChanged = this.onExotwiinUserChanged.bind(this);
    this.onExotwiinsChanged = this.onExotwiinsChanged.bind(this);

    this._userSubscription = this._coreAuthRepository.user$.subscribe(
      this.onUserChanged
    );
    this._exotwiinUserSubscription =
      this._exotwiinUserService.exotwiinUserObservable.subscribe(
        this.onExotwiinUserChanged
      );
    this._exotwiinsSubscription =
      this._exotwiinUserService.exotwiinsObservable.subscribe(
        this.onExotwiinsChanged
      );
  }

  public dispose(): void {
    this._exotwiinsSubscription.unsubscribe();
    this._exotwiinUserSubscription.unsubscribe();
    this._userSubscription.unsubscribe();
    this._model.dispose();
  }

  public async createExotwiinAsync(name: string): Promise<void> {
    if (!this._model.user) {
      return;
    }

    this._model.isExotwiinLoading = true;
    try {
      this._model.selectedExotwiin = await this._exotwiinService.requestCreateAsync(this._model.user.id, name);
      await this.getExotwiinsAsync();
      this._model.isExotwiinLoading = false;
    } catch (_error: any) {
      this._model.isExotwiinLoading = false;
    }
  }

  public async getExotwiinsAsync(): Promise<void> {
    if (!this._model.exotwiinUser) {
      return;
    }

    await this._exotwiinUserService.requestGetExotwiinsAsync(
      this._model.exotwiinUser.uuid
    );
  }

  public async signOutAsync(): Promise<void> {
    this._exotwiinUserService.clearExotwiins();
    await this._authService.signOut();
  }

  private onUserChanged(
    value:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ): void {
    this._model.user = value;
    this._coreFeatureFlag.identify(value?.id);
  }

  private async onExotwiinUserChanged(value: ExotwiinUserJson | undefined): Promise<void> {
    this._model.exotwiinUser = value;

    if (value) {
      await this._exotwiinUserService.requestGetExotwiinsAsync(value.uuid);
      this._model.isExotwiinInitialLoad = false;
    }
  }

  public clearExotwiin(){
    this._model.exotwiins = [];
  }

  private onExotwiinsChanged(value: ExotwiinJson[]): void {

    const exotwiins: ExotwiinInterface[] = [];

    for (const json of value) {
      const fbxExt = json.fbxUri.split('.').pop();
      const objExt = json.objUri.split('.').pop();
      const gifExt = json.gifUri.split('.').pop();
      let status = ExotwiinStatus.InProgress;
      let svgUri = gifExt?.includes('gif') ? json.gifUri : '../../../../../assets/icon/twiin_placeholder.svg';
      if (gifExt?.includes('gif')) {
        status = ExotwiinStatus.Raw3DPreview;
        svgUri = json.gifUri;
      }

      const month = json.date.toLocaleString('default', { month: 'long' });
      const day = json.date.getDate();
      const year = json.date.getFullYear();
      const exotwiin: ExotwiinInterface = {
        name: json.name,
        uuid: json.uuid,
        date: `${month} ${day}, ${year}`,
        status: status,
        svgUri: svgUri,
        scanId: json.scanId,
        fbxUri: json.fbxUri,
        objUri: json.objUri,
        gifUri: json.gifUri,
        mp4Uri: json.mp4Uri,
      };

      exotwiins.push(exotwiin);
    }
    this._model.exotwiins = exotwiins;
  }
}
