import {
  ChangeDetectorRef,
  AfterContentChecked,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StatusState } from '@ngneat/elf-requests';
import {
  CoreAuthRepository,
  CoreAuthService,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { Subscription, Subject } from 'rxjs';

import { ConfirmedValidator } from './custom-validator';
import { CustomToast } from './custom-toast-model.model';
import { AuthenticationController } from '../../../../../controllers';

@Component({
  selector: 'exotwiin-ui-auth-reset-password',
  templateUrl: './exotwiin-ui-reset-password.page.html',
  styleUrls: ['./exotwiin-ui-reset-password.page.scss'],
})
export class ResetPasswordPage
  implements OnInit, OnDestroy, AfterContentChecked
{
  codeQuantity = 6;
  customToastData: CustomToast | undefined;
  eventsSubject: Subject<unknown> = new Subject<unknown>();
  headerStatus: string;
  isCodeCompleted = false;
  isPasswordResettled = false;
  loadingSubscriptions: Array<Subscription> = [];
  resetPasswordForm: FormGroup;
  resetPasswordSubmitted = false;
  showSpinner = false;
  showToastAlert = false;
  strengthPopoverData: Array<any> = [];
  storeValue = this.coreAuthRepository.storeValue;
  submitPasswordForm: FormGroup;
  invalidCode = false;
  routeSub: any; // subscription to route observer

  constructor(
    private readonly _authenticationController: AuthenticationController,
    private authService: CoreAuthService,
    private changeDetector: ChangeDetectorRef,
    private coreAuthRepository: CoreAuthRepository,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.submitPasswordForm = fb.group(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        code: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[0-9]*$/),
          Validators.pattern(/^.{6,}$/),
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/\d/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/\W/),
        ]),
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );

    this.headerStatus = 'backPreviousPage';
  }

  ngOnInit() {
    this.customToastData = {
      customClass: '',
      customIcon: 'alert-circle',
      customText: '',
      customTime: 1000,
      customEmail: '',
    };

    this.strengthPopoverData.push({
      text: 'strengthPassword.strengthText',
      textValidator: '',
      textClass: '',
    });

    this.strengthPopoverData.push({
      text: '',
      textClass: 'strength-text-bold',
      textValidator:
        'capital letter, a number and a symbol  ( !  @  #  $  %  ^  &  * )',
    });

    this.setLoadingSubscriptions();

    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.resetFormState();
      }
    });
  }

  resetFormState() {
    this.resetPasswordForm.reset();
    this.submitPasswordForm.reset();
    this.isPasswordResettled = false;
    this.resetPasswordSubmitted = false;
    this.isCodeCompleted = false;
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * Get email Field
   */
  get getEmail() {
    return this.resetPasswordForm.value.email;
  }

  /**
   * Get password Field
   */
  get getPassword() {
    return this.submitPasswordForm.value.password;
  }

  /**
   * On click Header go back Previous Page
   */
  handleRedirection() {
    if (!this.isPasswordResettled && !this.resetPasswordSubmitted) {
      this.router.navigate(['/auth']);
    } else if (this.isPasswordResettled && !this.isCodeCompleted) {
      this.isPasswordResettled = false;
    } else if (this.isCodeCompleted) {
      this.isCodeCompleted = false;
    } else if (this.resetPasswordSubmitted) {
      this.resetPasswordSubmitted = false;
    }
  }

  /**
   * Reset Password
   */
  public resetPassword() {
    if (!this.resetPasswordForm.valid) return;
    this.customToastData = {
      customClass: 'alert-toast-class',
      customIcon: 'alert-circle',
      customText: 'resetPassword.resendNewCode',
      customTime: 4000,
      customEmail: this.resetPasswordForm.value.email,
    };

    if (this.isPasswordResettled) {
      this.showToastAlert = true;

      setTimeout(() => {
        this.showToastAlert = false;
      }, this.customToastData.customTime);
    }

    this._authenticationController.requestPasswordResetAsync(
      this.resetPasswordForm.get('email')?.value
    );
  }

  /**
   * Submit Reset Password
   * Code/Email/Password
   */
  public submitResetPassword() {
    if (!this.submitPasswordForm.valid) return;

    this._authenticationController.submitPasswordResetAsync(
      this.submitPasswordForm.get('email')?.value,
      this.submitPasswordForm.get('code')?.value,
      this.submitPasswordForm.get('password')?.value
    );
  }

  /**
   * Set loadingSubscriptions onInit
   * And get logs after submit
   */
  async setLoadingSubscriptions() {
    this.setResetPassword();
    this.setSubmitResetPassword();
  }

  /**
   * Set Reset Password onInit
   * And get logs after submit
   */
  async setResetPassword() {
    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.RESET_PASSWORD)
        .subscribe(({ value: statusState }: StatusState) => {
          switch (statusState) {
            case 'pending':
              {
                this.showSpinner = true;
              }
              break;
            case 'error':
              {
                this.showSpinner = false;
              }
              break;
            case 'success':
              {
                const currentEmailValue: string =
                  this.resetPasswordForm.get('email')?.value;
                this.submitPasswordForm.setValue({
                  email: currentEmailValue,
                  code: '',
                  password: '',
                  confirmPassword: '',
                });
                this.showSpinner = false;
                this.isPasswordResettled = true;
              }
              break;
          }
        })
    );
  }

  /**
   * Set Submit Reset Password onInit
   * And get logs after submit
   */
  async setSubmitResetPassword() {
    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(
          CoreAuthStoreProvider.AuthRequest.SUBMIT_RESET_PASSWORD
        )
        .subscribe((statusState: any) => {
          switch (statusState.value) {
            case 'pending':
              {
                this.showSpinner = true;
              }
              break;
            case 'error':
              {
                if (
                  statusState.error.code === 'CodeMismatchException' ||
                  statusState.error.code === 'ExpiredCodeException'
                ) {
                  this.invalidCode = true;
                  this.isCodeCompleted = false;
                }
                this.showSpinner = false;
              }
              break;
            case 'success':
              {
                this.showSpinner = false;
                this.isCodeCompleted = false;
                this.isPasswordResettled = false;
                this.resetPasswordSubmitted = true;
              }
              break;
          }
        })
    );
  }

  isMinimumLenghtMet(): boolean {
    const { password } = this.submitPasswordForm.value;

    return password && password.length >= 8;
  }

  isThereNumber(): boolean {
    const { password } = this.submitPasswordForm.value;

    return password && password.match(/\d/);
  }

  isThereUppercaseLetter(): boolean {
    const { password } = this.submitPasswordForm.value;

    return password && password.match(/[A-Z]/);
  }

  isThereLowercaseLetter(): boolean {
    const { password } = this.submitPasswordForm.value;

    return password && password.match(/[a-z]/);
  }

  isThereSymbol(): boolean {
    const { password } = this.submitPasswordForm.value;

    return password && password.match(/\W/);
  }

  doesPasswordMatch(): boolean {
    const { password, confirmPassword } = this.submitPasswordForm.value;

    return password.length > 0 && password === confirmPassword;
  }

  /**
   * Set the Code filled by the User
   * @param event Event emitted on fill input
   */
  setCode(event: any) {
    this.isCodeCompleted = true;
    this.submitPasswordForm.patchValue({
      email: this.getEmail,
      code: event,
    });
  }

  ngOnDestroy() {
    this.loadingSubscriptions.forEach((sub) => sub.unsubscribe());
    this.routeSub.unsubscribe();
  }
}
