import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { CoreAuthRepository } from '../repositories';
import { CoreAuthAmplifyProvider, CoreAuthStoreProvider } from '../providers';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AuthRequest } from '../providers/core-auth-store.provider';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthFacebookGuard implements CanActivate {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly router: Router,
    private readonly http: HttpClient
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<UrlTree | true> {
    const redirectSuccess: string = route?.data?.redirectSuccess;
    const redirectError: string = route?.data?.redirectError;

    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN_FEDERATED,
        'pending'
      );

      await this.coreAuthAmplify.currentSession();

      const user = await lastValueFrom(
        this.http.get<CoreAuthStoreProvider.AuthProps['user']>(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/user-handler/v1/user`
        )
      );

      this.coreAuthRepository.updateUser(user);
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN_FEDERATED,
        'success'
      );

      return redirectSuccess ? this.router.parseUrl(redirectSuccess) : true;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        CoreAuthStoreProvider.AuthRequest.SIGNIN_FEDERATED,
        'error',
        error
      );

      if (redirectError) {
        this.router.parseUrl(redirectError);
      }

      throw error;
    }
  }
}
