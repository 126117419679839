<exotwiin-ui-header></exotwiin-ui-header>

<ion-content>
        <section>
            <div class="flex flex-col justify-center items-center lg:mt-20">
                <div class="text-[25px] lg:text-display-3 font-exo font-regular text-white">MEET YOUR VIRTUAL</div>
                <div class="text-[45px] lg:text-display-1 font-exo font-bold leading-[3.5rem] text-white">TWIIN</div>
                <p class="text-p3 font-poppins text-regular text-neutral-100">
                    The only certifiable
                    <span class="text-p3 text-semibold gradient">Avatar</span> identity !
                </p>
            </div>
        </section>

        <section [ngStyle]="{'background-image': 'url(../../../../assets/images/exotwiin_landing_illustration.svg)',
        'background-repeat': 'no-repeat', 'background-position': 'center', 'height': '55vh', 'background-size': 'contain'}"
            class="flex justify-end h-[500px] items-center flex-col">
            <div class="relative h-[500px] w-full">
                <img src="url(../../../../assets/images/comicon_logo.svg"
                    class="absolute top-[100px] w-[120px] left-[40px]" alt="">
            </div>
            <button (click)="redirect()" class="w-[80%] max-w-[330px] text-h6 text-white flex items-center justify-center p-3 
            bg-primary-500 rounded-md">
                <p>Get <span class="font-bold">Twiined</span></p>
            </button>
        </section>

        <section style="height: 8vh;" class="flex justify-center items-center mt-2 flex-col w-[100%] max-w-[400px] m-auto">
            <a href="http://exotwiin.com" class="text-primary-500 text-p2">Learn more about ExoTwiin</a>

            <div class="flex items-center justify-around flex-row w-[75%] mt-4 mb-1">
                <p class="text-neutral-500 text-caption" (click)="redirectToTermsAndConditions()">Terms & Conditions</p>
                <p class="text-neutral-500 text-caption" (click)="redirectToPrivacyPolicy()">Privacy Settings</p>
            </div>
        </section>
</ion-content>