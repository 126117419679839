import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CoreAuthService } from '@serious-stack/core/auth/angular';

@Injectable({
  providedIn: 'root',
})
export class ExotwiinUIAuthService {
  constructor(
    private readonly coreAuthService: CoreAuthService,
    private readonly translateService: TranslateService,
    private readonly titleCase: TitleCasePipe,
    private readonly alertController: AlertController,
    private readonly router: Router
  ) {}
  async openConfirmSignUpSuccess() {
    const alert = await this.alertController.create({
      header: this.translateService.instant(
        'signUp.emailVerificationSucceeded'
      ),
      message: this.translateService.instant('signUp.nowSignIn'),
      buttons: [
        {
          text: this.titleCase.transform(
            this.translateService.instant('general.dismiss')
          ),
          handler: () => {
            return this.router.navigate(['auth', 'signin']);
          },
        },
      ],
    });

    return alert.present();
  }

  async openConfirmSignUp(username: string) {
    const alert = await this.alertController.create({
      header: this.translateService.instant('signUp.emailVerification'),
      message: this.translateService.instant('signUp.enterEmail'),
      inputs: [
        {
          name: 'verificationCode',
          type: 'text',
          placeholder: this.translateService.instant('signUp.verificationCode'),
        },
      ],
      buttons: [
        {
          text: this.titleCase.transform(
            this.translateService.instant('general.cancel')
          ),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: this.titleCase.transform(
            this.translateService.instant('general.verify')
          ),
          role: 'verification',
          handler: async ({ verificationCode }) => {
            await this.coreAuthService.signUpConfirm({
              username,
              code: verificationCode,
            });

            // this.openConfirmSignUpSuccess();
          },
        },
      ],
    });

    return alert.present();
  }
}
