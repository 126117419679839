import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { CompanyName, RoleName, User } from '@prisma/client';
import { ExotwiinInterface } from '../interfaces/exotwiin.interface';
import { Model } from '../model';

export interface DetailsProps {
  user:
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined;
    exotwiinList: ExotwiinInterface[];
    exotwiin: ExotwiinInterface | undefined;
    exotwiinUuid: string;
    scanId: string;
  }

@Injectable({
  providedIn: 'root',
})
export class DetailsModel extends Model {
  constructor() {
    const { state, config } = createState(
      withProps<DetailsProps>({
        user: undefined,
        exotwiinList: [],
        exotwiin: undefined,
        exotwiinUuid: '',
        scanId: '',
      })
    );
    super(
      new Store({
        name: 'details',
        state: state,
        config: config,
      })
    );
  }

  public get user():
    | (User & {
        userRoleCompanies: Array<{
          companyName: CompanyName;
          roleName: RoleName;
        }>;
      })
    | undefined {
    return this.store.getValue().user;
  }

  public set user(
    value:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ) {
    this.store.update((state) => ({ ...state, user: value }));
  }

  public get exotwiinList(): ExotwiinInterface[] {
    return this.store.getValue().exotwiinList;
  }

  public set exotwiinList(value: ExotwiinInterface[]) {
    this.store.update((state) => ({ ...state, exotwiinList: value }));
  }

  public get exotwiin(): ExotwiinInterface | undefined {
    return this.store.getValue().exotwiin;
  }

  public set exotwiin(value: ExotwiinInterface | undefined) {
    this.store.update((state) => ({ ...state, exotwiin: value }));
  }

  public set exotwiinUuid(value: string) {
    this.store.update((state) => ({ ...state, exotwiinUuid: value }));
  }

  public get exotwiinUuid(): string {
    return this.store.getValue().exotwiinUuid;
  }

  public get scanId(): string {
    return this.store.getValue().scanId;
  }

  public set scanId(value: string) {
    this.store.update((state) => ({ ...state, scanId: value }));
  }
}
