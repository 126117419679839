import { Inject, Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { from, lastValueFrom, mergeMap } from 'rxjs';
import { CoreAuthRepository } from '../repositories';
import { CoreAuthAmplifyProvider, CoreAuthStoreProvider } from '../providers';
import { HttpClient } from '@angular/common/http';
import { AuthRequest } from '../providers/core-auth-store.provider';
import { AmplifyChallengeName } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthService {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly http: HttpClient
  ) {}

  async signUp({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP,
        'pending'
      );

      const signup = await lastValueFrom(
        from(
          this.coreAuthAmplify.signUp({
            username,
            password,
            attributes: {
              email: username,
            },
          })
        )
      );

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP,
        'success'
      );

      return signup;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP,
        'error',
        error
      );

      throw error;
    }
  }

  async signIn({ username, password }: { username: string; password: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN,
        'pending'
      );

      await lastValueFrom(
        from(this.coreAuthAmplify.signIn(username, password))
      );
      const user = await lastValueFrom(
        this.http.get<CoreAuthStoreProvider.AuthProps['user']>(
          `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/user-handler/v1/user`
        )
      );

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN,
        'success'
      );
      this.coreAuthRepository.updateUser(user);

      return user;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN,
        'error',
        error
      );
      return error;
    }
  }

  async completeInvitation({
    username,
    temporaryPassword,
    newPassword,
  }: {
    username: string;
    temporaryPassword: string;
    newPassword: string;
  }) {
    try {
      const user: CoreAuthStoreProvider.AuthProps['user'] = await lastValueFrom(
        from(
          this.coreAuthAmplify
            .signIn(username, temporaryPassword)
            .then(async (data) => {
              if (
                data.challengeName ===
                AmplifyChallengeName.NEW_PASSWORD_REQUIRED
              ) {
                await this.coreAuthAmplify.completeNewPassword(
                  data,
                  newPassword
                );
              }
            })
        ).pipe(
          mergeMap(() =>
            this.http.get<CoreAuthStoreProvider.AuthProps['user']>(
              `${process.env.NG_APP_EXOTWIIN_API_ENDPOINT}/user-handler/v1/user`
            )
          ),
          this.coreAuthRepository.trackRequestsStatus(AuthRequest.INVITATION)
        )
      );

      this.coreAuthRepository.updateUser(user);
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.INVITATION,
        'success'
      );
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.INVITATION,
        'error',
        error
      );

      throw error;
    }
  }

  async signInFederated(
    provider: 'facebook' | 'google' | CognitoHostedUIIdentityProvider
  ) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN_FEDERATED,
        'pending'
      );

      switch (provider) {
        case 'facebook':
          provider = CognitoHostedUIIdentityProvider.Facebook;
          break;
        case 'google':
          provider = CognitoHostedUIIdentityProvider.Google;
      }

      const signInFederated = await lastValueFrom(
        from(
          this.coreAuthAmplify.federatedSignIn({
            provider,
          })
        )
      );

      return signInFederated;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN_FEDERATED,
        'error',
        error
      );

      throw error;
    }
  }

  async signOut() {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNOUT,
        'pending'
      );

      this.coreAuthRepository.reset();
      await this.coreAuthAmplify.signOut();

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNOUT,
        'success'
      );
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNOUT,
        'error',
        error
      );

      throw error;
    }
  }

  async signUpConfirm({ username, code }: { username: string; code: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_CONFIRM,
        'pending'
      );

      const signUpConfirm = await lastValueFrom(
        from(this.coreAuthAmplify.confirmSignUp(username, code))
      );

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_CONFIRM,
        'success'
      );

      return signUpConfirm;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_CONFIRM,
        'error',
        error
      );

      return "ERROR";
    }
  }

  async resendCode({ email }: { email: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE,
        'pending'
      );

      const resendCode = await this.coreAuthAmplify.resendSignUp(email);

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE,
        'success'
      );

      return resendCode;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE,
        'error',
        error
      );

      throw error;
    }
  }

  async resetPassword({ username }: { username: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.RESET_PASSWORD,
        'pending'
      );

      const resetPassword = await lastValueFrom(
        from(this.coreAuthAmplify.forgotPassword(username))
      );

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.RESET_PASSWORD,
        'success'
      );

      return resetPassword;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.RESET_PASSWORD,
        'error',
        error
      );

      throw error;
    }
  }

  async submitResetPassword({
    username,
    code,
    password,
  }: {
    username: string;
    code: string;
    password: string;
  }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SUBMIT_RESET_PASSWORD,
        'pending'
      );

      const submitResetPassword = await lastValueFrom(
        from(
          this.coreAuthAmplify.forgotPasswordSubmit(username, code, password)
        )
      );

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SUBMIT_RESET_PASSWORD,
        'success'
      );

      return submitResetPassword;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SUBMIT_RESET_PASSWORD,
        'error',
        error
      );

      throw error;
    }
  }
}
