import { Store } from '@ngneat/elf';
import { DisposableInterface } from './interfaces/disposable.interface';

export class Model implements DisposableInterface {
  private _store: Store;

  constructor(store: Store) {
    this._store = store;
  }

  public get store(): Store {
    return this._store;
  }

  public dispose(): void {}
}
