import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ExotwiinUIComponent } from './exotwiin-ui.component';
import { ExotwiinUIRoutingModule } from './exotwiin-ui-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  CoreAuthSettingsStoreProvider,
  CoreAuthSettingsRepository,
} from '@serious-stack/core/auth/angular';
import { CoreErrorTrackingModule } from '@serious-stack/core/error-tracking/angular';
import { ExotwiinAuthModule } from './modules/auth/exotwiin-ui-auth.module';
import { ExotwiinService, ExotwiinUserService } from '../services';
import { ExotwiinUIController } from '../controllers';
import { ExotwiinUIModel } from '../models';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.error(
      `Missing translation 😜 : ${params.key} for language ${params.translateService.store.langs}`
    );
    return `!⚠️ ${params.key} ⚠️!`;
  }
}

@NgModule({
  declarations: [ExotwiinUIComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    ExotwiinUIRoutingModule,
    ExotwiinAuthModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      extend: true,
    }),
    CoreErrorTrackingModule,
  ],
  providers: [
    ExotwiinUserService,
    ExotwiinService,
    ExotwiinUIModel,
    ExotwiinUIController,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          translateService: TranslateService,
          coreAuthSettingsRepository: CoreAuthSettingsRepository
        ) =>
        () => {
          const currentLang =
            coreAuthSettingsRepository.storeValue.currentLanguage ||
            CoreAuthSettingsStoreProvider.AvailableLanguages.EN;

          translateService.addLangs([
            CoreAuthSettingsStoreProvider.AvailableLanguages.EN,
          ]);
          translateService.setDefaultLang(
            CoreAuthSettingsStoreProvider.AvailableLanguages.EN
          );

          return translateService.use(currentLang);
        },
      deps: [TranslateService, CoreAuthSettingsRepository],
      multi: true,
    },
  ],
  bootstrap: [ExotwiinUIComponent],
})
export class ExotwiinUIModule {}
