import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RoleName } from '@prisma/client';
import { CoreAuthRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthRolesGuard implements CanActivate {
  constructor(
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const routeRoles: Array<RoleName> = route.data?.roles;
    const redirectError: string = route.data?.redirectError;

    let userRole: RoleName | undefined;
    try {
      userRole = this.coreAuthRepository.getUserRoleByCompany();

      if (
        !userRole ||
        (routeRoles != undefined && !routeRoles.includes(userRole))
      ) {
        throw new Error('Forbiddden');
      }
    } catch (error: any) {
      if (redirectError) {
        return this.router.parseUrl(redirectError);
      }

      return false;
    }

    return true;
  }
}
