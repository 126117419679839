import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewChildren,
  AfterContentChecked,
} from '@angular/core';

@Component({
  selector: 'exotwiin-ui-validation-code',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.scss'],
})
export class ValidationCodeComponent implements AfterContentChecked {
  @Input() codeNumberClass = '';
  @Input() invalidCode = false;
  @Input() codeNumberQuantity = 0;
  @Output() codeCompleted = new EventEmitter<string>();
  @ViewChild('codeInput') codeInput!: HTMLInputElement;
  @ViewChildren('codeItems') codeItems: any;
  codeArray: Array<string> = [];
  codeNotCompleted = false;
  wrongCode = false;

  constructor() { }

  /**
   * Creates an array from codeNumberQuantity
   * @param i Quantity of code number
   */
  counter(i: number) {
    return new Array(i);
  }

  ngAfterContentChecked() {
    if (this.invalidCode &&
      this.codeInput &&
      this.codeInput.value !== '' &&
      !this.wrongCode) {
      this.wrongCode = true;
      this.codeArray = [];
      this.codeItems._results[0].el.children[0].setFocus();
    }
  }

  /**
   * On change the value in the Inputs
     * If not the last input move the focus to th next Input
     * Or next value it's not Empty
   * And fill Input code value from Array Code
   * Emit the Code to Parent Component
   * @param position Array position
   * @param event Event emitted on fill input
   */
  fillCodeValue(position: number, event: any) {
    event.stopPropagation();
    if (event.detail.value !== '' &&
      event.detail.value !== undefined) {
      this.codeArray[position] = event.detail.value;

      if (position + 1 < this.codeNumberQuantity &&
        (this.codeItems._results[position + 1].el.children[0].value === '' ||
          this.codeItems._results[position + 1].el.children[0].value === undefined)) {
        this.codeItems._results[position + 1].el.children[0].setFocus();
      }
    }

    if (this.codeArray.length === this.codeNumberQuantity
      && !this.codeArray.includes('')
      && !this.codeArray.some(element => element === undefined)) {
      this.wrongCode = false;
      const inputCode = this.codeArray.join('');
      this.codeInput.value = '';
      this.codeArray = [];
      this.codeArray.length = 0
      this.codeItems._results[0].el.children[0].setFocus();

      this.codeCompleted.emit(inputCode);
    } else {
      this.codeNotCompleted = false;
      this.codeInput.value = '';
    }

    if (this.codeInput
      && this.codeInput.value.length !== this.codeNumberQuantity) {
      this.codeNotCompleted = true;
    }
  }

  /**
   * On click an input clear the field
   * and if first position, remove all the other (reset the input)
   * @param position Array position
   * @param event Event emitted on click input
   */
  changePosition(position: number, event: any) {
    event.stopPropagation();
    if (this.codeArray[position]){
      this.codeArray[position] = '';
    }
    this.handleFocusRepositionning(position);
    this.fillCodeValue(position + 1, event)
  }

  handleFocusRepositionning(position: number) {
    this.codeArray.forEach((value, _i) => {
      if (_i > position) {
        this.codeArray[_i] = '';
      }
    })
  }

  /**
   * Check if value is Decimal
   * @param event Event emitted on fill input
   */
  decimalOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * Handle paste event
    * checking the value is decimal
   * @param event ClipboardEvent emitted on paste
   */
  onPaste(event: ClipboardEvent) {
    const pattern = /[0-9]/;

    if (event.clipboardData) {
      const arrayPasted = event.clipboardData.getData('text').split('');

      arrayPasted.forEach((value, index) => {
        if (!pattern.test(value)) {
          event.preventDefault();
        } else {
          if (index < this.codeNumberQuantity) {
            this.codeArray[index] = value;
          }
        }
      });
    }
  }
}
