import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { CompanyName, RoleName, User } from '@prisma/client';
import { CoreAuthRepository } from '@serious-stack/core/auth/angular';
import { CoreFeatureFlagService } from '@serious-stack/core/feature-flag/angular';
import { Subscription } from 'rxjs';
import { DisposableInterface } from '../interfaces/disposable.interface';
import { ExotwiinInterface } from '../interfaces/exotwiin.interface';
import { ExotwiinJson } from '../json/exotwiin.json';
import { HomeModel } from '../models';
import { DetailsModel } from '../models/details.model';
import { ExotwiinUserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class DetailsController implements DisposableInterface {
  private readonly _userSubscription: Subscription;
  private readonly _exotwiinsSubscription: Subscription;

  constructor(
    private readonly _model: DetailsModel,
    private readonly _coreAuthRepository: CoreAuthRepository,
    private readonly _coreFeatureFlag: CoreFeatureFlagService,
    private readonly _exotwiinUserService: ExotwiinUserService,
    private readonly _homeModel: HomeModel,
  ) {
    this.onUserChanged = this.onUserChanged.bind(this);

    this._userSubscription = this._coreAuthRepository.user$.subscribe(this.onUserChanged);
    this._exotwiinsSubscription = this._homeModel.store.pipe(select((model: HomeModel) => model.exotwiins)).subscribe((exotwiinList: Array<ExotwiinInterface>) => {
      this._model.exotwiinList = exotwiinList;
    });

  }

  public dispose(): void {
    this._userSubscription.unsubscribe();
    this._model.dispose();
  }

  private onUserChanged(
    user:
      | (User & {
          userRoleCompanies: Array<{
            companyName: CompanyName;
            roleName: RoleName;
          }>;
        })
      | undefined
  ): void {
    this._model.user = user;
    this._coreFeatureFlag.identify(user?.id);
  }

  public setSelectedExotwiin(uuid: string): void {
    const exotwiin = this._model.exotwiinList.find(
      (exotwiin: ExotwiinInterface) => exotwiin.uuid === uuid
    );
    this._model.exotwiin = exotwiin;
    this._model.scanId = exotwiin?.scanId ?? '';
  }
}
