<exotwiin-ui-header></exotwiin-ui-header>

<ion-content>
  <section *ngIf="!isSignUpDone" class="flex flex-col items-center justify-between">
    <section class="flex flex-col justify-center text-center">
      <div class="font-poppins text-h4 font-bold text-white mt-3">Time to Get Twiined</div>
      <div class="mt-1 mb-7 font-poppins text-p3 font-regular text-neutral-100 w-full">
        <div>
          {{ 'signUp.titlePartOne' | translate }}
        </div>
        <div>
          {{ 'signUp.titlePartTwo' | translate }}
        </div>
      </div>
      <div class="border rounded-lg border-neutral-600 flex py-2">
        <div class="rounded-lg bg-neutral-700 w-7 h-7 mx-3">
          <div class="ml-[0.6rem] mt-[0.3rem]">
            <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.23333 18.0001V9.554H0V6.51299H2.23333V3.91557C2.23333 1.8745 3.55257 0.00012207 6.59237 0.00012207C7.82314 0.00012207 8.73324 0.118112 8.73324 0.118112L8.66153 2.9579C8.66153 2.9579 7.73338 2.94886 6.72053 2.94886C5.62433 2.94886 5.44871 3.45403 5.44871 4.29249V6.51299H8.74867L8.60508 9.554H5.44871V18.0001H2.23333Z"
                fill="#E7E5EB" />
            </svg>
          </div>
        </div>
        <button class="opacity-100 font-poppins text-p2 text-white font-medium mt-1"
          (click)="signInWithSSO('facebook')">
          {{ 'signIn.signInWithFacebook' | translate }}
        </button>
      </div>
    </section>

    <section class="flex flex-row items-center my-5">
      <div class="border-b border-neutral-800 w-24 flex-1"></div>
      <div class="font-poppins text-p3 text-neutral-600 font-regular mx-3">{{ 'signIn.orWithEmail' | translate }}
      </div>
      <div class="border-b border-neutral-800 w-24 flex-1"></div>
    </section>

    <form class="flex flex-col w-min" [formGroup]="form" (ngSubmit)="completeSignUpForm()">
      <!-- Email -->
      <div class="flex flex-col">
        <label class="font-poppins text-p3 font-medium text-neutral-400 mb-1">
          {{ 'forms.email' | translate | titlecase }}
        </label>
        <input
          class="w-80 py-3 border-2 border-transparent px-3 bg-white rounded-md text-p1 font-poppins font-regular text-neutral-900 hover:border-2 hover:border-primary-200"
          [class.invalid]="!form.controls.email.valid
                        && (form.controls.email.dirty
                        || form.controls.email.touched)
                        || invalidPassword" type="email" (onChange)="invalidPassword = false" formControlName="email"
          [placeholder]="'forms.enterEmail' | translate" inputmode="email" autocomplete="email" required="true" />
      </div>

      <!-- Errors -->
      <div *ngIf="form.controls.email.invalid
              && (form.controls.email.dirty || form.controls.email.touched)">
        <!-- Required -->
        <div class="font-poppins font-regular text-caption text-neutral-400 pt-1"
          *ngIf="form.controls.email.errors?.['required']">
          {{ 'forms.requiredField' | translate }}
        </div>
        <!-- Invalid format -->
        <div class="font-poppins font-regular text-caption text-neutral-400 pt-1"
          *ngIf="form.controls.email.errors?.['email']">
          {{ 'forms.invalidEmail' | translate }}
        </div>
      </div>

      <!-- Password -->
      <div class="flex flex-col mt-4">
        <label class="font-poppins text-p3 font-medium text-neutral-400 mb-1">
          {{ 'forms.password' | translate }}
        </label>
        <input
          class="w-80 py-3 px-3 bg-white rounded-md border-2 border-transparent text-p1 font-poppins font-regular text-neutral-900 hover:border-2 hover:border-primary-200"
          [class.invalid]="!form.controls.password.valid" type="password" (ionChange)="invalidPassword = false"
          formControlName="password" [placeholder]="'forms.enterPassword' | translate" inputmode="password"
          autocomplete="password" required="true" />
        <div class="flex flex-col justify-between mt-4 text-p3 text-neutral-400">
          <span *ngIf="model.userExistsError" class="text-red-500">
            * {{'signUp.accountAlreadyExists' | translate}}</span>
          <span class="{{this.isMinimumLenghtMet() ? 'text-green-500' : 'text-red-500'}}">*
            {{'resetPassword.characterCountCriteria' | translate}}</span>
          <span class="{{this.isThereNumber() ? 'text-green-500' : 'text-red-500'}}">* {{'resetPassword.numberCriteria'
            |
            translate}}</span>
          <span class="{{this.isThereUppercaseLetter() ? 'text-green-500' : 'text-red-500'}}">*
            {{'resetPassword.uppercaseCriteria' | translate}}</span>
          <span class="{{this.isThereLowercaseLetter() ? 'text-green-500' : 'text-red-500'}}">*
            {{'resetPassword.lowercaseCriteria' | translate}}</span>
          <span class="{{this.isThereSymbol() ? 'text-green-500' : 'text-red-500'}}">* {{'resetPassword.symbolCriteria'
            |
            translate}}</span>
        </div>
      </div>

      <!-- Errors -->
      <div *ngIf="form.controls.password.invalid
              && (form.controls.password.dirty || form.controls.password.touched)">
        <!-- Required -->
        <div class="font-poppins font-regular text-caption text-neutral-400 pt-1"
          *ngIf="form.controls.password.errors?.['required']">
          {{ 'forms.requiredField' | translate }}
        </div>
        <!-- Invalid format -->
        <div class="font-poppins font-regular text-caption text-neutral-400 pt-1" *ngIf="invalidPassword">
          {{ 'forms.invalidEmail' | translate }}
        </div>
      </div>

      <button
        class="{{this.form.valid ? 'opacity-100' : 'opacity-50' }} bg-primary-500 rounded-lg py-3 text-white text-h6 font-medium mt-5">
        {{ 'signUp.getStarted' | translate }}
      </button>
    </form>

    <!-- Footer -->
    <section class="px-6 mt-16 mb-5 font-poppins text-caption text-neutral-500 text-center">
      {{ 'signIn.byClickingSignUp' | translate }} <span class="font-semibold text-primary-500">{{
        'signIn.termsCondition' | translate }}</span> and <span
        class="font-semibold text-primary-500">{{ 'signIn.privacyPolicy' | translate }}</span>.
    </section>
  </section>


  <!-- NEW FORM EMAIL INPUT -->
  <ion-content *ngIf="isSignUpDone" class="flex reset-password-content">
    <ion-grid class="flex items-center justify-center h-[75vh] p-0">
      <ion-col class=" flex-col ion-align-items-center ion-justify-content-center p-0" size="12" size-lg="7">
        <div *ngIf="!isCodeDone" class="flex flex-col p-0 mt-5" size="12" size-lg="8">
          <form class="ion-align-items-center flex flex-col h-full ion-justify-content-center w-full"
            [formGroup]="codeForm">

            <ion-col *ngIf="!isCodeCompleted" class="flex flex-col text-center px-3 py-0" size="12" size-lg="8">
              <p class="text-h4 font-bold d-lg-none d-block">
                {{'resetPassword.confirmEmail' | translate | titlecase}}
              </p>
              <p class="text-center text-p3 text-neutral-400 mb-6 ">
                {{'resetPassword.resetPasswordCodeSubtitle1' | translate}}
                <br />
                {{getEmail}}
              </p>

            </ion-col>

            <!-- Set Code -->
            <exotwiin-ui-validation-code *ngIf="!isCodeCompleted" [invalidCode]="invalidCode"
              (codeCompleted)="setCode($event)" [codeNumberQuantity]="codeQuantity" [codeNumberClass]="'code-class'">
            </exotwiin-ui-validation-code>

            <p *ngIf="codeError" class="text-red-500 text-center m-auto pt-4">
              {{ 'signUp.codeError' | translate | titlecase }}
            </p>

            <p *ngIf="!isCodeCompleted" class="text-center text-p3 text-neutral-400 mt-6">
              {{'resetPassword.didntReceive' | translate}} <br />
              {{'resetPassword.checkYourSpam' | translate}}
              <span class="text-primary-500">{{'resetPassword.resendCode' | translate}}.</span>
            </p>

            <!-- To here -->
            <a class="cancel-button ion-text-center mt-3 d-lg-block hidden">
              {{ "general.cancel" | translate | titlecase }}
            </a>
          </form>
        </div>

        <!-- Reset Password Done -->
        <div *ngIf="isCodeDone" class="ion-align-items-center flex flex-col mt-4 w-full px-1">
          <h1 class="mt-4 mb-1 text-center w-full text-h4 font-bold">
            {{ "signUp.confirmedEmail" | translate | titlecase }}!
          </h1>
          <h3 class="text-p3 text-neutral-100 weight-400 text-center lg:mx-5 my-0">
            {{ "signUp.successfullAccount" | translate }}
          </h3>

          <img class="w-32 my-10 self-center" src="../../../../../assets/icon/circle-check.svg">

          <!-- Log In Button -->
          <button class="w-full bg-primary-500 mt-4 p-2 rounded-md" [routerLink]="['/auth']" type="submit">
            {{ 'signIn.login' | translate }}
          </button>
          <!-- To here -->
        </div>
      </ion-col>
    </ion-grid>
  </ion-content>
</ion-content>
