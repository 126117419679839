import { InjectionToken } from '@angular/core';
import { Auth } from '@aws-amplify/auth';

export type providerType = typeof Auth;
export const providerToken = new InjectionToken<providerType>('AMPLIFY_AUTH', {
  providedIn: 'root',
  factory: () => {
    Auth.configure({
      region: process.env.NG_APP_AMPLIFY_REGION as string,
      userPoolId: process.env.NG_APP_AMPLIFY_USER_POOL_ID as string,
      userPoolWebClientId: process.env
        .NG_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID as string,
      oauth: {
        domain: process.env.NG_APP_AMPLIFY_OAUTH_DOMAIN as string,
        scope: process.env.NG_APP_AMPLIFY_SCOPE?.split(',') as Array<string>,
        redirectSignIn: process.env.NG_APP_AMPLIFY_REDIRECT_SIGN_IN as string,
        redirectSignOut: process.env.NG_APP_AMPLIFY_REDIRECT_SIGN_OUT as string,
        type: process.env.NG_APP_AMPLIFY_TYPE as string,
        responseType: process.env.NG_APP_AMPLIFY_RESPONSE_TYPE as string,
      },
      authenticationFlowType: process.env
        .NG_APP_AMPLIFY_AUTHENTICATION_FLOW_TYPE as string,
    });

    return Auth;
  },
});
