<exotwiin-ui-back-button-header></exotwiin-ui-back-button-header>
<ion-content>
  <section>
    <div class="flex flex-col child:flex child:flex-col child:mx-0 child:mb-6 w-[80%] m-auto">
      <section class="p-0 w-[80%] min-w-fit">
        <div class="text-2xl mb-10 font-bold">{{ this.nonCollapsableSections.title | titlecase }}</div>
        <div class="text-sm text-neutral-400">{{ 'termsAndConditions.lastUpdate' | translate }}: {{
          this.lastEditionDate.toLocaleDateString() }}</div>
        <div class="flex flex-col child:mb-10">
          <ng-container *ngFor="let paragraph of this.nonCollapsableSections.text">
            <span class="text-regular text-p3">
              {{ paragraph }}
            </span>
          </ng-container>
        </div>
      </section>
      <section class="p-0 min-w-fit" *ngFor="let section of collapsableSections">
        <div class="text-xl mb-2">{{section.title | titlecase}}</div>
        <ng-container *ngIf="section.open.toString() === 'false'; else seeLess">
          <div (click)="openSection(section.title)" class="text-sm text-neutral-400">{{'termsAndConditions.seeMore' |
            translate}}</div>
        </ng-container>
        <ng-template #seeLess>
          <div class="text-regular text-p3 flex flex-col child:mb-5">
            <span *ngFor="let paragraph of section.text">
              {{ paragraph }}
            </span>
          </div>
          <div (click)="closeSection(section.title)" class="text-sm text-neutral-400">{{'termsAndConditions.seeLess' |
            translate}}</div>
        </ng-template>
      </section>
    </div>
  </section>
</ion-content>