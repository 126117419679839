import { JsonInterface } from '../interfaces/json.interface';

export class ExotwiinJson implements JsonInterface<ExotwiinJson> {
  private _uuid: string;
  private _exotwiinUserId: string;
  private _scanId: string;
  private _name: string;
  private _date: Date;
  private _fbxUri: string;
  private _objUri: string;
  private _gifUri: string;
  private _mp4Uri: string;

  constructor() {
    this._uuid = '';
    this._exotwiinUserId = '';
    this._scanId = '';
    this._name = '';
    this._date = new Date();
    this._fbxUri = '';
    this._objUri = '';
    this._gifUri = '';
    this._mp4Uri = '';
  }

  public get uuid(): string {
    return this._uuid;
  }

  public get exotwiinUserId(): string {
    return this._exotwiinUserId;
  }

  public get scanId(): string {
    return this._scanId;
  }

  public get name(): string {
    return this._name;
  }

  public get date(): Date {
    return this._date;
  }

  public get fbxUri(): string {
    return this._fbxUri;
  }

  public get objUri(): string {
    return this._objUri;
  }

  public get gifUri(): string {
    return this._gifUri;
  }

  public get mp4Uri(): string {
    return this._mp4Uri;
  }

  public deserialize(json: string): ExotwiinJson {
    const object = JSON.parse(json);
    this._uuid = object['uuid'];
    this._exotwiinUserId = object['exotwiinUserId'];
    this._scanId = object['scanId'];
    this._name = object['name'];
    this._date = new Date(object['date']);
    this._fbxUri = object['fbxUri'];
    this._objUri = object['objUri'];
    this._gifUri = object['gifUri'];
    this._mp4Uri = object['mp4Uri'];

    return this;
  }
}
