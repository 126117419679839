import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RoleName } from '@prisma/client';
import {
  CoreAuthGuard,
  CoreAuthRolesGuard,
} from '@serious-stack/core/auth/angular';
import { ExotwiinUIComponent } from './exotwiin-ui.component';
import { LandingPage } from './modules/app/pages/landing/exotwiin-ui-landing.page';
import { PrivacyPolicyPage } from './modules/app/pages/privacy-policy/exotwiin-ui-privacy-policy-page.component';
import { TermsAndConditionsPage } from './modules/app/pages/terms-and-conditions/exotwiin-ui-terms-and-conditions-page.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/app/exotwiin-ui-app.module').then(
        (m) => m.ExotwiinUIAppModule
      ),
    canActivate: [CoreAuthGuard, CoreAuthRolesGuard],
    data: {
      roles: [RoleName.super_admin, RoleName.admin, RoleName.user],
      redirectError: 'auth',
    },
  },
  {
    path: 'landing',
    component: LandingPage,
    data: { roles: [RoleName.super_admin, RoleName.admin, RoleName.user] },
  },
  {
    path: 'landing/terms-and-conditions',
    component: TermsAndConditionsPage,
    data: { roles: [RoleName.super_admin, RoleName.admin, RoleName.user] },
  },
  {
    path: 'landing/privacy-policy',
    component: PrivacyPolicyPage,
    data: { roles: [RoleName.super_admin, RoleName.admin, RoleName.user] },
  },
  {
    path: '',
    component: ExotwiinUIComponent,
    data: { roles: [RoleName.super_admin, RoleName.admin, RoleName.user] },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class ExotwiinUIRoutingModule {}
